import { Sidebar } from 'primereact/sidebar';
import React, { useRef, useState } from 'react';
import ENV from '../../ENV';

const ProductAsk = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({ qty: 0 })

    const index = props.cartlist.findIndex((i) => i.id === props.item.id);

    // console.log(index, indx, props.cartlist, props.item)

    const invalid = useRef()

    const onAddCart = () => {
        if (inputvalue.qty > 0) {
            if (props.cartlist[index]?.id === props.item.id) {
                props.cartlist[index].qty = Number(inputvalue.qty);                
                if (props.cartlist[index].qty >= inputvalue.qty) {
                    sessionStorage.setItem("cart_list", JSON.stringify([...props.cartlist]))
                    props.setCartList([...props.cartlist])
                    setOpenDialog(prev => ({ ...prev, open: false }))
                }
            } else {
                sessionStorage.setItem("cart_list", JSON.stringify([...props.cartlist, { ...props.item, qty: inputvalue.qty, "user_id": sessionStorage.getItem('user_id') }]))
                props.setCartList([...props.cartlist, { ...props.item, qty: inputvalue.qty, "user_id": sessionStorage.getItem('user_id') }])
                setOpenDialog(prev => ({ ...prev, open: false }))
            }
        } else {
            // alert("qty error")
            invalid.current.style.border = "1px solid red";
        }
    }


    const increase = async () => {
        if (!index) {
            props.cartlist[index].qty = (props.cartlist[index]?.qty) + Number(1);
            setInputValue(prev => ({ ...prev, qty: Number(props.cartlist[index]?.qty) }))
        } else {
            setInputValue(prev => ({ ...prev, qty: Number(1)+Number(prev.qty) }))
        }
    }

    // console.log(props.cartlist[index]?.id, inputvalue)

    const decrease = async () => {
        if (inputvalue.qty > 0) {
            if (!index) {
                props.cartlist[index].qty = (props.cartlist[index]?.qty) - Number(1);
                setInputValue(prev => ({ ...prev, qty: Number(props.cartlist[index]?.qty) }))
            } else {
                setInputValue(prev => ({ ...prev, qty: Number(prev.qty - 1) }))
            }
        }
    }

    const showDialog = (event) => {
        if (props.cartlist[index]?.id === props.item.id) {
            setInputValue(prev => ({ ...prev, qty: Number(props.cartlist[index]?.qty) }))
            setOpenDialog(prev => ({ ...prev, open: true }))
            return
        }
        setInputValue(prev => ({ ...prev, qty: 0 }))
        setOpenDialog(prev => ({ ...prev, open: true }))
    }

    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    // sessionStorage.removeItem('cart_list');


    return (
        <>

            <Sidebar
                showCloseIcon={false}
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>{props?.item.product_name}</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 200, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                            {/* <span style={{ color: "#3e3e3e", fontSize: 15 }}>Available Stock: 0</span> */}
                            <span style={{ color: "#3e3e3e", fontSize: 15 }}>Price: {props?.item.price}</span>
                        </div>
                        <div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                <span onClick={(e) => increase()} className='pi pi-plus-circle' style={{ fontSize: 30 }}></span>
                                <input ref={invalid} type='number' name='qty' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))} value={inputvalue.qty} style={{ width: 70, height: 40, borderRadius: 5, border: "1px solid #ddd", textAlign: "center" }} />
                                <span onClick={(e) => decrease()} className='pi pi-minus-circle' style={{ fontSize: 30 }}></span>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            props.item.id === props.cartlist[index]?.id ?
                                <button onClick={() => onAddCart()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Update</button>
                                :
                                <button onClick={() => onAddCart()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Add Cart</button>
                        }
                    </div>
                </div>
            </Sidebar>




            <div onClick={() => showDialog()} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, border: "1px solid #000", backgroundColor: "#fff", borderRadius: 5 }}>
                <div style={{ width: 50, height: 50, backgroundColor: "#ddd" }}>
                    <img src={ENV.IMAGE+props?.item.product_image} style={{ width: 50, height: 50, border: "5px solid #fff", borderRadius: 5 }} />
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bolder" }}>{props?.item.product_name}</span>
                    {/* <span style={{ color: "#3e3e3e", fontSize: 10 }}>Available Stock: {([...props.stock].filter((i) => Number(i.product_id) === Number(props.item.id) && Number(i.store_id) === Number(sessionStorage.getItem('store_id')))[0]?.qty)}</span> */}
                    <span style={{ color: "#3e3e3e", fontSize: 10 }}>Price: {props?.item.price}</span>
                    {
                        props.cartlist[index]?.id === props.item.id ?
                        <span style={{ color: "orange", fontSize: 10 }}>On Cart</span>
                        :null
                    }
                </div>
            </div>
        </>
    );
}

export default ProductAsk;
