import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import ProductAsk from './component/cashier/ProductAsk';
import ProductScan from './component/cashier/ProductScan';
import CartFloat from './component/cashier/CartFloat';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Cashier = ({ userInfo }) => {
    const [inputvalue, setInputValue] = useState({qty: 0})
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [product, setProduct] = useState([])
    const [stock, setStock] = useState([])
    const [search, setSearch] = useState('')

    const loadproduct = async () => {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            const arr = res.data.map(obj => ({ ...obj, "qty": 0}))
            setProduct(arr)
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("allStock", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStock(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])
    


    if(sessionStorage.getItem('cart_list')) {
        var cart_list = sessionStorage.getItem('cart_list');
        // console.log(1)
    } else {
        var cart_list = JSON.stringify([]);
        // console.log(2)
    }
    // const listData = JSON.parse(cart_list);
    const [cartlist, setCartList] = useState(JSON.parse(cart_list))


    // console.log(product)
    

    
    useEffect(()=>{
        loadproduct()
    }, [])

    console.log(sessionStorage.getItem('user_id'))
    console.log(sessionStorage.getItem('store_id'))

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Cashier" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        <div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop:10, paddingBottom: 10}}>
                                <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search Product' style={{width: "90%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd"}} />
                                <ProductScan product={product} setSearch={setSearch} cartlist={cartlist} setCartList={setCartList} stock={stock} />
                            </div>
                        </div>
                        <div>  
                            <CartFloat cartlist={cartlist} />                      
                            <div style={{display: "flex", flexDirection: "column", gap: 5, overflowY: "scroll", height: window.innerHeight - 150}} className='hidescroll'>
                                {
                                    [...product].filter((i)=>{
                                        return i?.product_name?.toLowerCase().includes(search.trim().toLowerCase()) && i?.status === 'active'
                                    }).map((item, key) => {
                                        if (([...stock].filter((i) => Number(i.product_id) === Number(item.id) && Number(i.store_id) === Number(sessionStorage.getItem('store_id'))).map((i)=>i.qty).slice(0)) > 0) {
                                            return (
                                                <ProductAsk key={key} item={item} product={product} cartlist={cartlist} setCartList={setCartList} stock={stock} />
                                            )
                                        }
                                    }).slice(0, 30)
                                }
                            </div>
                            
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default Cashier;
