import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import ReportCard from './component/report/ReportCard';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Report = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [store, setStore] = useState([])
    const [sales, setSales] = useState([])
    const [product, setProduct] = useState([])
    const [salesitem, setSalesItem] = useState([])
    const [user, setUser] = useState([])
    const [topproduct, setTopProduct] = useState([])
    const [topstore, setTopStore] = useState([])
    const [search, setSearch] = useState('')

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sales_item", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSalesItem(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setProduct(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("user", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("top_product_report", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setTopProduct(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("top_store_report", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setTopStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])


    
    const groupedData = salesitem?.reduce((groups, item) => {
        const { product_id } = item;
        // console.log(product_id)
        if (product_id == 0) {
            if (!groups[product_id]) {
                groups[product_id] = [];
            }
            groups[product_id].push(item);
        } else {
            if (!groups[product_id]) {
                groups[product_id] = [];
            }
            groups[product_id].push(item);
        }
        return groups;
    }, {});

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Report" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        {/* <div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop:10, paddingBottom: 10}}>
                                <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search Customer Name / Order ID' style={{width: "100%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd"}} />
                            </div>
                        </div> */}
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 15, fontWeight: "bolder"}}>TOP Product Report</span>
                            <span onClick={()=>window.location.assign(`/report/product/toplist`)} style={{fontSize: 15, fontWeight: "bolder", textDecoration: "underline"}}>Show All</span>
                        </div>   
                        <div>                               
                            <div style={{display: "flex", flexDirection: "column", gap: 1, overflowY: "scroll"}} className='hidescroll'>
                                {/* {
                                    Object.entries(groupedData).map(([id, items]) => {
                                        return (
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", padding: 5, borderRadius: 5, backgroundColor: "#fff"}}>
                                                <span>{[...product].filter((i)=>i.id === items[0].product_id)[0]?.product_name}</span>
                                                <span>{items?.reduce((qty, item) => qty = Number(item.qty) + Number(qty), 0)}</span>
                                            </div>
                                        )
                                    })
                                } */}
                                {
                                    [...topproduct].map((item, key) => {
                                        return (
                                            <div onClick={()=>window.location.assign(`product/report/${item.product_id}`)} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", padding: 5, borderRadius: 5, backgroundColor: "#fff"}}>
                                                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                                    <span>{key+1}</span>
                                                    <span>{[...product].filter((i)=>Number(i.id) === Number(item.product_id)).map((i)=>i.product_name).slice(0)}</span>
                                                </div>
                                                <span>{item.avg}</span>
                                                {/* <span>{item?.reduce((qty, item) => qty = Number(item.qty) + Number(qty), 0)}</span> */}
                                            </div>
                                        )
                                    }).slice(0,10)
                                }
                            </div>
                            
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 10, paddingBottom: 5}}>
                            <span style={{fontSize: 15, fontWeight: "bolder"}}>TOP Store Selling Report</span>
                            <span onClick={()=>window.location.assign(`/report/store/toplist`)} style={{fontSize: 15, fontWeight: "bolder", textDecoration: "underline"}}>Show All</span>
                        </div>   
                        <div>                               
                            <div style={{display: "flex", flexDirection: "column", gap: 1, overflowY: "scroll"}} className='hidescroll'>
                                {/* {
                                    Object.entries(groupedData).map(([id, items]) => {
                                        return (
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", padding: 5, borderRadius: 5, backgroundColor: "#fff"}}>
                                                <span>{[...product].filter((i)=>i.id === items[0].product_id)[0]?.product_name}</span>
                                                <span>{items?.reduce((qty, item) => qty = Number(item.qty) + Number(qty), 0)}</span>
                                            </div>
                                        )
                                    })
                                } */}
                                {
                                    [...topstore].map((item) => {
                                        return (
                                            <div onClick={()=>window.location.assign(`/store/details/${item.store_id}`)} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", padding: 5, borderRadius: 5, backgroundColor: "#fff"}}>
                                                <span>{[...store].filter((i)=>Number(i.id) === Number(item.store_id)).map((i)=>i.store_name).slice(0)}</span>
                                                <span>{item.avg}</span>
                                                {/* <span>{item?.reduce((qty, item) => qty = Number(item.qty) + Number(qty), 0)}</span> */}
                                            </div>
                                        )
                                    }).slice(0,5)
                                }
                            </div>
                            
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignitem: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 15, fontWeight: "bolder"}}>Sales Report</span>
                            <span style={{fontSize: 15, fontWeight: "normal"}}>Today</span>
                        </div>   
                        <div>                               
                            <div style={{display: "flex", flexDirection: "column", gap: 5, overflowY: "scroll", height: window.innerHeight - 150}} className='hidescroll'>
                                {
                                    [...sales].filter((i)=>{
                                        return i?.customer_name?.toLowerCase().includes(search.trim().toLowerCase()) || i?.id?.toLowerCase().includes(search.trim().toLowerCase())
                                    }).map((item) => {
                                        return (
                                            <ReportCard item={item} user={user} store={store} salesitem={salesitem} />
                                        )
                                    }).slice(0,10)
                                }
                            </div>
                            
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Report;
