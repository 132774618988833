import { format } from 'date-fns';
import React from 'react';

const ReportCard = (props) => {
    const SubTotal = props.salesitem?.filter((i)=>i.sales_id === props.item.id).reduce((amount, item) => amount = (item.price * item.qty) + amount, 0);
    const TotalQty = props.salesitem?.filter((i)=>i.sales_id === props.item.id).reduce((qty, item) => qty = Number(item.qty) + Number(qty), 0);
    return (
        <div onClick={()=>window.location.assign(`/report/details/${props.item.id}`)}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#fff", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, minHeight: 50, border: "1px solid #000", borderRadius: 5 }}>
                <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                    <span style={{fontWeight: "bolder"}}>{props.item.customer_name}</span>
                    <span style={{color: "#3e3e3e", fontSize: 12}}>Store: {[...props?.store].filter((i)=>i.id === props.item.store_id).map((i)=>i.store_name).slice(0)}</span>
                    <span style={{color: "#3e3e3e", fontSize: 12}}>Cashier: {[...props.user].filter((i)=>i.id === props.item.user_id).map((i)=>i.name).slice(0)}</span>
                    <span style={{color: "#3e3e3e", fontSize: 12}}>Order ID: {props.item.id}</span>
                    <span style={{color: "#3e3e3e", fontSize: 12}}>Total Item: {TotalQty}</span>
                    <span style={{color: "#3e3e3e", fontSize: 12}}>Sub-Total: {SubTotal}</span>
                    <span style={{color: "#3e3e3e", fontSize: 12}}>Date Purchased: {format(new Date(props.item.date_time), 'MMMM dd, yyyy hh:mm:ss a')}</span>
                    {/* <span style={{padding: 5, fontSize: 15, width: 100, height: 20, border: "1px solid #ddd", borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>stock-in</span> */}
                </div>
            </div>
        </div>
    );
}

export default ReportCard;
