import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import swal from 'sweetalert';

const ProductAdd = (props) => {
    const [inputvalue, setInputValue] = useState({...props.item, 'ifSelectedImage': false})
    const [openDialog, setOpenDialog] = useState({ open: false, loading: false })
    const [category, setCategory] = useState([])
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("category", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setCategory(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const addProductBtn = async () => {
        var formData = new FormData();
        formData.append("add_product", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        setOpenDialog(prev=>({...prev, loading: true}))
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            if ([...res.data].filter((i)=>i.save === 'success').length > 0) {
                swal({
                    // title: "Invalid!",
                    text: "Successfully Saved",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(()=>{
                    console.log(res.data)
                    props.setProduct([...res.data])
                    setInputValue({"product_name":"", "product_code":"", "price":0})
                    hideDialog()
                    setOpenDialog(prev=>({...prev, loading: false}))
                });  
            } else {
                swal({
                    // title: "Invalid!",
                    text: "Invalid",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(()=>{
                    console.log(res.data)
                    props.setProduct([...res.data])
                    setOpenDialog(prev=>({...prev, loading: false}))
                    // setInputValue({"product_name":"", "product_code":"", "price":0})
                    // hideDialog()
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const updateProductBtn = async () => {
        var formData = new FormData();
        formData.append("update_product", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setProduct([...res.data])
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                setInputValue(prev=>({ ...prev, 'product_image': e.target.result, 'ifSelectedImage': true }))
            }
        }
    };



    return (
        <>

        <Sidebar
            icons={
                <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                    {
                        props.status === 'edit' ?
                        <>
                            Edit Product
                        </>
                        :
                        <>
                            Add Product
                        </>
                    }
                </div>
            }
            blockScroll={true}
            position='bottom'
            draggable={false}
            style={{ width: '100rem', height: 650, fontSize: 15 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <label>Product Image</label>
                        {inputvalue?.ifSelectedImage ?
                            <label>
                                <input type="file" placeholder="" style={{ display: "none" }} className="required" onChange={(e) => changeHandlerImage(e)} />
                                <img src={inputvalue?.product_image} style={{ width: 200, height: 200, border: "5px solid #fff", borderRadius: 5 }} />
                            </label>
                            :
                            <label>
                                <div style={{ width: 200, height: 200, backgroundColor: "#ddd" }}>
                                    <input type="file" placeholder="" style={{ display: "none" }} className="required" onChange={(e) => changeHandlerImage(e)} />
                                    <img src={ENV.IMAGE+inputvalue?.product_image} style={{ width: 200, height: 200, border: "5px solid #fff", borderRadius: 5 }} />
                                </div>
                            </label>
                        }
                    </div>
                    <div>
                        <label>Product Name</label>
                        <input name='product_name' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.product_name} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Product Code</label>
                        <input name='product_code' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.product_code} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Category</label>
                        <select name='category_id' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.category_id} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}}>
                            <option selected disabled></option>
                            {
                                [...category].map((i)=>{
                                    return (
                                        <option value={i.id}>{i.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <label>Cost</label>
                        <input name='cost' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.cost} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Price</label>
                        <input name='price' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.price} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        {
                            props.status === 'edit' ?
                            <button onClick={()=>updateProductBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Update</button>
                            :
                            (
                                openDialog.loading ?
                                <button style={{width: "100%", cursor: "no-drop", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#ddd", color: "#fff"}}>Please wait...</button>
                                :
                                <button onClick={()=>addProductBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Save New</button>
                            )
                        }
                    </div>
                </div>
        </Sidebar>
           {
            props.status === 'edit' ?
            <button onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} style={{width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#264682", color: "#fff"}}>Edit</button>
            :
            <span onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} className='pi pi-plus-circle' style={{fontSize: 25}}></span> 
           }
        </>
    );
}

export default ProductAdd;
