import React from 'react';

const OptionalQty = (props) => {

    const index = props.cartdata.findIndex((i) => {
        return i.id === props.item.id;
    });

    const increase = async () => {
        props.cartdata[index].qty = Number(props.cartdata[index].qty)+Number(1);
        sessionStorage.setItem("cart_list", JSON.stringify([...props.cartdata]))
        props.setCartList([...props.cartdata])
    }

    const decrease = async () => {
        if (Number(props.cartdata[index].qty) > 1) {
            props.cartdata[index].qty = Number(props.cartdata[index].qty)-Number(1);
            sessionStorage.setItem("cart_list", JSON.stringify([...props.cartdata]))
            props.setCartList([...props.cartdata])
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
            <span onClick={()=>increase()} className='pi pi-plus-circle' style={{ fontSize: 20 }}></span>
            <input type='number' value={props.item.qty} style={{ width: 50, borderRadius: 5, border: "1px solid #ddd", textAlign: "center" }} />
            <span onClick={()=>decrease()} className='pi pi-minus-circle' style={{ fontSize: 20 }}></span>
        </div>
    );
}

export default OptionalQty;
