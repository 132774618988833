import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';

const StockCount = (props) => {
    const [inputvalue, setInputValue] = useState({ ...props.item })
    const [openDialog, setOpenDialog] = useState({ open: false })
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const saveBtn = async () => {
        // alert(JSON.stringify({ ...inputvalue, 'store_id': props.store_id }))
        // return;
        if (inputvalue.count>0) {
            var formData = new FormData();
            formData.append("add_count", 1);
            formData.append("inputvalue", JSON.stringify({ ...inputvalue, 'store_id': props.store_id }));
            await axios({
                method: "post",
                // url: 'http://localhost/gbless_store/api/index.php',
                url: ENV.DOMAIN + 'api/index.php',
                data: formData,
            }).then((res) => {
                // props.setStock([...res.data])
                hideDialog()
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <>

            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                        <div><span>{props.item.product_name}</span></div>
                        <div><span style={{fontSize: 15, color: "#ddd"}}>Stock Count</span></div>
                    </div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', fontSize: 15, height: 200 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10}}>
                        <input type='number' placeholder='0' name='count' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", textAlign: "center"}} />
                        <button onClick={()=>saveBtn()} style={{borderRadius: 5, border: "1px solid #ddd", width: 150}}>Save Count</button>
                    </div>
            </Sidebar>

            <button onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "rgb(4 125 129)", color: "#fff" }}>Stock Count</button>
        </>
    );
}

export default StockCount;
