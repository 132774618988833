import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ENV from '../../ENV';
import StockCount from './StockCount';

const AddStockFormPanel = (props) => {
    const [inputvalue, setInputValue] = useState({ ...props.item, "store_id": props.store_id })
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [sales, setSales] = useState([])
    const [category, setCategory] = useState([])

    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }
    const ShowDialog = (event) => {
        setInputValue({ ...props.item })
        setOpenDialog(prev => ({ ...prev, open: true }))
    }

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("category", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setCategory(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("salesitem", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])
    // console.log(inputvalue)

    const addEmployeeBtn = async () => {
        var formData = new FormData();
        formData.append("add_employee", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            props.setEmployee([...res.data])
            setInputValue(prev => ({ ...prev, 
                "firstname": "",
                "middlename": "",
                "lastname": "",
                "gender": "",
                "birthday": "",
                "address": ""
            }))
            hideDialog()
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const updateCategoryBtn = async () => {
        var formData = new FormData();
        formData.append("update_employee", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            props.setEmployee([...res.data])
            hideDialog()
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const removeCategoryBtn = async () => {
        var formData = new FormData();
        formData.append("update_category", 1);
        formData.append("inputvalue", JSON.stringify({ ...inputvalue, "status": "inactive" }));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            props.setCategory([...res.data])
            hideDialog()
        })
            .catch((err) => {
                console.log(err);
            });
    }


    const AddStockBtn = async () => {
        if (inputvalue.qty>0) {
            var formData = new FormData();
            formData.append("add_stock", 1);
            formData.append("inputvalue", JSON.stringify({ ...inputvalue, 'store_id': props.store_id }));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/index.php',
                data: formData,
            }).then((res) => {
                props.setStock([...res.data])
                hideDialog()
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    }



    const totalSales = [...sales].filter((i)=>Number(i.store_id) === Number(props.store_id) && Number(i.product_id) === Number(inputvalue.id))?.reduce((amount, item) => amount = Number(item.qty) + amount, 0)


    return (
        <>

            <Sidebar
                showCloseIcon={false}
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 250, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                        <label>Current Stock</label>
                        <label>{([...props.stock].filter((i) => Number(i.store_id) === Number(props.store_id) && Number(i.product_id) === Number(inputvalue.id)).map((i)=>i.qty).slice(0) ?? 0)-totalSales}</label>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                        <label>Input Qty</label>
                        <input name='qty' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} autoComplete='off' style={{  width: 100, height: 40, textAlign: "center", padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                    {/* <div style={{padding: 10, width: 100}}>
                        {JSON.stringify(props)}
                    </div> */}
                    <button onClick={()=>AddStockBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Add Stocks</button>
                    <StockCount store_id={props.store_id} item={props.item} />
                    {/* <button onClick={()=>window.location.assign(`/store/stock/history/${props.store_id}/${props.item.id}`)} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"rgb(4 125 129)", color: "#fff"}}>View History</button> */}
                </div>
            </Sidebar>
            <div onClick={() => ShowDialog()} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, border: "1px solid #000", borderRadius: 5 }}>
                <div style={{ width: 60, height: 60, backgroundColor: "#ddd" }}>
                    <img src={ENV.IMAGE+props.item.product_image} style={{ width: 60, height: 60, border: "2px solid #fff", borderRadius: 5 }} />
                </div>
                {/* {JSON.stringify(totalSales)} */}
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bolder" }}>{props.item.product_name}</span>
                    <span style={{ color: "#3e3e3e", fontSize: 12 }}>Stock: {([...props.stock].filter((i) => Number(i.store_id) === Number(props.store_id) && Number(i.product_id) === Number(inputvalue.id))[0]?.qty ?? 0)-totalSales} </span>
                    {/* <span style={{ color: "#3e3e3e", fontSize: 12 }}>Stock: {JSON.stringify(props.stock)}</span> */}
                    <span style={{ color: "#374151", fontSize: 12 }}>Category: {[...category].filter((i)=>i.id === props.item.category_id).map((i)=>i.name).slice(0)}</span>
                    <span style={{ color: "#3e3e3e", fontSize: 12 }}>Price: {props.item.price}</span>
                    {/* <span style={{padding: 5, fontSize: 15, width: 100, height: 20, border: "1px solid #ddd", borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>stock-in</span> */}
                </div>
            </div>
        </>
    );
}

export default AddStockFormPanel;
