import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ENV from '../../ENV';
import SetLoginAccess from './SetLoginAccess';

const FormPanel = (props) => {
    const [inputvalue, setInputValue] = useState({ ...props.item })
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [sales, setSales] = useState([])
    const [store, setStore] = useState([])

    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }
    const ShowDialog = (event) => {
        setInputValue({ ...props.item })
        setOpenDialog(prev => ({ ...prev, open: true }))
    }

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])
    useMemo(async()=> {
        var formData = new FormData();
        formData.append("salesitem", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])
    // console.log(inputvalue)

    const addEmployeeBtn = async () => {
        var formData = new FormData();
        formData.append("add_employee", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            props.setEmployee([...res.data])
            setInputValue(prev => ({ ...prev, 
                "firstname": "",
                "middlename": "",
                "lastname": "",
                "gender": "",
                "birthday": "",
                "address": ""
            }))
            hideDialog()
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const updateCategoryBtn = async () => {
        var formData = new FormData();
        formData.append("update_employee", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            props.setEmployee([...res.data])
            hideDialog()
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const removeCategoryBtn = async () => {
        var formData = new FormData();
        formData.append("update_category", 1);
        formData.append("inputvalue", JSON.stringify({ ...inputvalue, "status": "inactive" }));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            props.setCategory([...res.data])
            hideDialog()
        })
            .catch((err) => {
                console.log(err);
            });
    }








    return (
        <>

            <Sidebar
                showCloseIcon={false}
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: props.status === 'add' ? window.innerHeight : window.innerHeight, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <label>Photo</label>
                        <div style={{ width: 150, height: 150, borderRadius: 5, border: "1px solid #ddd" }}>

                        </div>
                    </div> */}
                    <div>
                        <label>First Name</label>
                        <input name='firstname' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.firstname} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                    <div>
                        <label>Middle Name</label>
                        <input name='middlename' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.middlename} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input name='lastname' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.lastname} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                    <div>
                        <label>Gender</label>
                        <input name='gender' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.gender} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                    <div>
                        <label>Birthday</label>
                        <input name='birthday' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.birthday} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                    </div>
                    <div>
                        <label>Address</label>
                        <textarea name='address' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.address} style={{ width: "100%", padding: 5, borderRadius: 5, border: "1px solid #ddd" }}></textarea>
                    </div>
                    <div>
                        {
                            props.status === 'add' ?
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                    <button onClick={() => hideDialog()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#c96059", color: "#fff" }}>Cancel</button>
                                    <button onClick={() => addEmployeeBtn()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Save New</button>
                                </div>
                                :
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                        <button onClick={() => hideDialog()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#b56a28", color: "#fff" }}>Close</button>
                                        <button onClick={() => updateCategoryBtn()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Update</button>
                                    </div>
                                    {
                                        [...sales].filter((i) => i.user_id === inputvalue.id).map((i) => i).length > 0 ?
                                            <>
                                                <hr />
                                                <div>
                                                    <button onClick={() => window.location.assign(`/employee/sales/report/${inputvalue.id}`)} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "rgb(34 108 141)", color: "#fff" }}>Show Sales Reports</button>
                                                </div>
                                            </>
                                            : null
                                    }
                                    <hr />
                                    <div>
                                        <span style={{fontWeight: "bolder"}}>Login Access</span>
                                    </div>
                                    <div>
                                        <SetLoginAccess item={props.item} />
                                    </div>
                                    <hr />
                                    <div>
                                        {/* <button style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#c96059", color: "#fff" }}>Remove this Employee</button> */}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </Sidebar>
            {
                props.status === 'add' ?
                    <div onClick={() => setOpenDialog(prev => ({ ...prev, open: true }))} style={{ width: 40, height: 40, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}><span className='pi pi-plus-circle' style={{ fontSize: 25 }}></span></div>
                    :
                    <div onClick={() => ShowDialog()}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#fff", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, border: "1px solid #000", borderRadius: 5 }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                {/* <span style={{fontWeight: "bolder"}}>{item.customer_name}</span> */}
                                <span style={{ color: "#3e3e3e", fontSize: 12 }}>Name: <span style={{fontWeight: "bolder"}}>{props.item.fullname}</span></span>
                                <span style={{ color: "#3e3e3e", fontSize: 12 }}>Assigned Store: <span style={{fontWeight: "bolder"}}>{[...store].filter((i)=> i.id === props.item.store_id)[0]?.store_name}</span></span>
                                {/* <span style={{color: "#3e3e3e", fontSize: 12}}>Assigned Store: {item.store_name}</span> */}
                                {/* <span style={{padding: 5, fontSize: 15, width: 100, height: 20, border: "1px solid #ddd", borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>stock-in</span> */}
                            </div>
                        </div>
                    </div>
                // <div onClick={() => ShowDialog()}>
                //     <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, minHeight: 50, border: "1px solid #000", borderRadius: 5 }}>
                //         <div style={{width: 50, height: 50, backgroundColor: "#ddd"}}></div>
                //         <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                //             <span style={{fontWeight: "bolder"}}>{props.item.name}</span>
                //         </div>
                //     </div>
                // </div>
            }
        </>
    );
}

export default FormPanel;
