import React, { useState } from 'react';
import OptionalQty from '../cashier/OptionalQty';
import OptionCartPanel from './OptionCartPanel';
import { Sidebar } from 'primereact/sidebar';
import { Sync } from '@mui/icons-material';
import ENV from '../../ENV';

const CartItemCard = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({ qty: 0 })
    

    const showDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: true }))
    }

    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const removeBtn = async () => {
        const updatedData = props.cartlist.filter((i) => i !== props?.item);
        props.setCartList([...updatedData]);
        sessionStorage.setItem("cart_list", JSON.stringify([...updatedData]));
        hideDialog()
    }



    return (
        <div>


            <Sidebar
                showCloseIcon={false}
                icons={
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%"}}>
                        <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Option</div>
                        <span style={{fontWeight: "bolder"}}>{props.item.product_name}</span>
                    </div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 200, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <button onClick={()=>removeBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "rgb(221 119 72)", color: "#fff"}}>Remove</button>
                </div>
            </Sidebar>




            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, minHeight: 50, border: "1px solid #374151", borderRadius: 5 }}>
                <div onClick={() => showDialog()} style={{width: 60, height: 60, backgroundColor: "#ddd"}}>
                    <img src={ENV.IMAGE+props?.item.product_image} style={{ width: 60, height: 60, border: "5px solid #fff", borderRadius: 5 }} />
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 0}}>
                    <span onClick={() => showDialog()} style={{fontWeight: "bolder"}}>{props.item.product_name}</span>
                    <span onClick={() => showDialog()} style={{color: "#3e3e3e", fontSize: 12}}>Price: {props.item.price}</span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                        <span onClick={() => showDialog()} style={{color: "#3e3e3e", fontSize: 12}}>Sub-Total: {Number(props.item.qty)*Number(props.item.price)}</span>
                        <OptionalQty item={props.item} cartdata={props.cartdata} setCartList={props.setCartList} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartItemCard;
