import React, { useState } from 'react';

const CartFloat = (props) => {
    
    const SubTotal = props.cartlist?.reduce((amount, item) => amount = (item.price * item.qty) + amount, 0);
    const TotalQty = props.cartlist?.reduce((qty, item) => qty = Number(item.qty)+Number(qty), 0);

    return (  
        <div style={{position: "fixed", right: 10, backgroundColor: props.cartlist.length > 0 ? "#374151" : "#ddd", bottom: 30, width: 60, height: 60, borderRadius: 100, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div onClick={()=>props.cartlist.length > 0 ? window.location.assign('/cart') : null} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <span className='pi pi-shopping-cart' style={{fontSize: 20, color: "#fff"}}></span>
                <div style={{position: "absolute", padding: 5, borderRadius: 5, border: props.cartlist.length > 0 ? "1px solid #374151" : "1px solid #ddd", right: 36, color: "#fff", bottom: 36, backgroundColor: props.cartlist.length > 0 ? "#374151" : "#ddd"}}>{TotalQty}</div>
            </div>
        </div> 
    );
}

export default CartFloat;
