import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ENV from '../../ENV';

const FormPanel = (props) => {
    const [inputvalue, setInputValue] = useState({...props.item})
    const [openDialog, setOpenDialog] = useState({ open: false })
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }
    const ShowDialog = (event) => {
        setInputValue({...props.item})
        setOpenDialog(prev => ({ ...prev, open: true }))
    }

    const addCategoryBtn = async () => {
        var formData = new FormData();
        formData.append("add_category", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setCategory([...res.data])
            setInputValue({"name":""})
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const updateCategoryBtn = async () => {
        var formData = new FormData();
        formData.append("update_category", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setCategory([...res.data])
            console.log(res.data)
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const removeCategoryBtn = async () => {
        var formData = new FormData();
        formData.append("update_category", 1);
        formData.append("inputvalue", JSON.stringify({...inputvalue, "status":"inactive"}));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setCategory([...res.data])
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }








    return (
        <>

            <Sidebar
                showCloseIcon={false}
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                        
                    </div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: props.status === 'add' ? 200 : 200, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <label>Product Image</label>
                        <div style={{width: 150, height: 150, borderRadius: 5, border: "1px solid #ddd"}}>

                        </div>
                    </div> */}
                    <div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>                            
                            <label>Category Name</label>
                            {
                                props.status !== 'add' ?
                                <a style={{textDecoration: "underline"}}>See our Product</a>
                                : null
                            }
                        </div>
                        <input name='name' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.name} style={{width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        {
                            props.status === 'add' ?
                            <button onClick={()=>addCategoryBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Save New</button>
                            :
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>
                                <button onClick={()=>removeCategoryBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#c96059", color: "#fff"}}>Remove</button>
                                <button onClick={()=>updateCategoryBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Update</button>
                            </div>
                        }
                    </div>
                </div>
            </Sidebar>
            {
                props.status === 'add' ?
                <div onClick={() => setOpenDialog(prev => ({ ...prev, open: true }))} style={{width: 40, height: 40, display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}><span className='pi pi-plus-circle' style={{fontSize: 25}}></span></div>
                :
                <div onClick={() => ShowDialog()}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, border: "1px solid #000", borderRadius: 5 }}>
                        {/* <div style={{width: 50, height: 50, backgroundColor: "#ddd"}}></div> */}
                        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                            <span style={{fontWeight: "bolder"}}>{props.item.name}</span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default FormPanel;
