import React, { useEffect, useMemo, useRef, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Payment = (props) => {
    const [inputvalue, setInputValue] = useState({"customer_name":"", "payment_reference":""})
    const [isloading, setIsLoading] = useState(true)
    // if (props.userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(props.userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';
    const toast = useRef(null);

    if(sessionStorage.getItem('cart_list')) {
        var cart_list = sessionStorage.getItem('cart_list');
        // console.log(1)
    } else {
        var cart_list = JSON.stringify([]);
        // console.log(2)
    }
    const [cartlist, setCartList] = useState(JSON.parse(cart_list))

    const cartdata = cartlist?.filter((i) => {
        return i;
    });

    const SubTotal = cartdata?.reduce((amount, item) => amount = (item.price * item.qty) + amount, 0);
    const TotalQty = cartdata?.reduce((qty, item) => qty = Number(item.qty)+Number(qty), 0);

    const CompletePurchaseBtn = async () => {
        let payment = false;

        if (inputvalue.payment === 'Cash') {            
            payment = true;
        } else if (inputvalue.payment === 'GCash' && inputvalue.payment_reference !== '') {            
            payment = true;
        } else if (inputvalue.payment === 'Maya' && inputvalue.payment_reference !== '') {            
            payment = true;
        }

        if (payment) {
            setIsLoading(false)
            var formData = new FormData();
            formData.append("complete_purchase", 1);
            formData.append("store_id", sessionStorage.getItem('store_id'));
            formData.append("user_id", sessionStorage.getItem('user_id'));
            formData.append("inputvalue", JSON.stringify(inputvalue));
            formData.append("cartdata", JSON.stringify(cartdata));
            await axios({
                method: "post",
                url: ENV.DOMAIN+'api/index.php',
                data: formData,
            }).then((res)=>{
                setIsLoading(true)
                toast.current.show({severity:'success', summary: 'Successfully', detail:'Order Completed', life: 3000});
                sessionStorage.removeItem('cart_list');
                window.location.assign('/cashier')
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            toast.current.show({severity:'error', summary: 'Error', detail:'Incompleted', life: 3000});
        }
    }


    if (cartdata.length === 0) {
        return (<>Invalid</>)
    }

    
    return (
        <div className="wrapper">
            <Toast ref={toast} />
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Payment" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        <div>    
                            <div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop:10, paddingBottom: 10}}>
                                    <input placeholder='Customer Name (optional)' name='customer_name' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: "90%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd", outline: "none"}} />
                                    <div style={{width: 40, height: 40, display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}><span className='pi pi-users' style={{fontSize: 25}}></span></div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop:10, paddingBottom: 10}}>
                                    <select name='payment' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: "90%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd", outline: "none"}}>
                                        <option value={""} selected disabled="disabled">Select Payment Method</option>
                                        <option value={"Cash"}>Cash</option>
                                        <option value={"GCash"}>GCash</option>
                                        <option value={"Maya"}>Maya</option>
                                    </select>
                                    {/* <input placeholder='Customer Name (optional)' name='customer_name' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: "90%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd", outline: "none"}} /> */}
                                    <div style={{width: 40, height: 40, display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}><span className='pi pi-money-bill' style={{fontSize: 25}}></span></div>
                                </div>
                                {
                                    inputvalue.payment === 'GCash' ?
                                        <input placeholder='Reference No.' name='payment_reference' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: "90%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd", outline: "none"}} />
                                    :
                                    inputvalue.payment === 'Maya' ?
                                        <input placeholder='Reference No.' name='payment_reference' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: "90%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd", outline: "none"}} />
                                    :
                                    null
                                }
                            </div>
                            <div style={{width: "100%", flex: 2}}>
                                <div style={{display: "flex", flexDirection: "column", gap: 2}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Total Item/s</span>
                                        <span>{TotalQty}</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Sub-Total</span>
                                        <span>{SubTotal}</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Tax</span>
                                        <span>0.00</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Discount</span>
                                        <span>0.00</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span style={{fontWeight: "bolder"}}>Grand Total</span>
                                        <span style={{fontWeight: "bolder"}}>{SubTotal}</span>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                    <button onClick={()=>window.location.assign('/cart')} style={{width: 50, height: 40, borderRadius: 5, border: "1px solid #374151", backgroundColor:"#ddd", color: "#fff"}}>
                                        <span className='pi pi-angle-left' style={{color: "#000"}}></span>
                                    </button>
                                    {
                                        isloading ?
                                            <button onClick={()=>CompletePurchaseBtn()} style={{ flex: 1, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Complete Purchase</button>
                                        :
                                            <button style={{ flex: 1, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"rgb(129, 131, 134)", color: "#fff"}}>Please Wait...</button>
                                    }
                                </div>
                            </div>
                            
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Payment;
