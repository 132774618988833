import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ProductEdit from './ProductEdit';
import ProductAdd from './ProductAdd';
import ENV from '../../ENV';
import axios from 'axios';

const ProductCard = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const onRemove = async (item) => {
        const newdata = {...item, status: "inactive", 'ifSelectedImage': false};
        
        var formData = new FormData();
        formData.append("update_product", 1);
        formData.append("inputvalue", JSON.stringify(newdata));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setProduct([...res.data])
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
        // console.log(newdata)
        // const index = props.product.findIndex((i)=>i.id === item.id);
        // props.product[index].status = 'inactive';
        // props.setProduct([...props.product])
        // hideDialog()
    }

    return (
        <div>

            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>{props?.item.product_name}</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 250, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                    <div>
                        <div>

                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            <ProductAdd item={props.item} setProduct={props.setProduct} status="edit" />
                            {/* <ProductEdit status="edit" /> */}
                            <button onClick={()=>window.location.assign(`product/report/${props.item.id}`)} style={{width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#89f450", color: "#fff"}}>Show Report</button>
                            {/* <button style={{width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#89a350", color: "#fff"}}>Stock-in</button> */}
                            <button onClick={()=>onRemove(props.item)} style={{width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#bf250a", color: "#fff"}}>Remove</button>
                        </div>
                    </div>
            </Sidebar>




            <div onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, minHeight: 50, border: "1px solid #000", borderRadius: 5 }}>
                <div style={{ width: 60, height: 60, backgroundColor: "#ddd" }}>
                    <img src={ENV.IMAGE+props.item.product_image} style={{ width: 60, height: 60, border: "2px solid #fff", borderRadius: 5 }} />
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bolder" }}>{props.item.product_name}</span>
                    {/* <span style={{ color: "#3e3e3e", fontSize: 12 }}>Stock: 0 </span> */}
                    <span style={{ color: "#374151", fontSize: 12 }}>Category: {[...props.category].filter((i)=>i.id === props.item.category_id).map((i)=>i.name).slice(0, 1)}</span>
                    <span style={{ color: "#3e3e3e", fontSize: 12 }}>Price: {props.item.price}</span>
                    <span style={{ color: "#3e3e3e", fontSize: 12 }}>Cost: {props.item.cost}</span>
                    {/* <span style={{padding: 5, fontSize: 15, width: 100, height: 20, border: "1px solid #ddd", borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>stock-in</span> */}
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
