import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import OptionalQty from './component/cashier/OptionalQty';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const ReportDetails = (props) => {
    const history = useHistory()
    const { sales_id } = useParams();

    // if (props.userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(props.userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [sales, setSales] = useState([])
    const [salesitem, setSalesItem] = useState([])
    const [product, setProduct] = useState([])
    const [store, setStore] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setStore(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setSales(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("sales_item", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setSalesItem(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setProduct(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    if (localStorage.getItem('cart_list')) {
        var cart_list = localStorage.getItem('cart_list');
        // console.log(1)
    } else {
        var cart_list = JSON.stringify([]);
        // console.log(2)
    }
    const [cartlist, setCartList] = useState(JSON.parse(cart_list))

    const cartdata = cartlist?.filter((i) => {
        return i;
    });

    const SubTotal = salesitem?.filter((i) => i.sales_id === sales_id)?.reduce((amount, item) => amount = (item.price * item.qty) + amount, 0);
    const TotalQty = salesitem?.filter((i) => i.sales_id === sales_id)?.reduce((amount, item) => amount = Number(item.qty) + Number(amount), 0);


    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Details" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">

                    <div className="container-fluid">
                        <div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5 }}>
                                <span style={{ fontSize: 15, fontWeight: "bolder" }}>Items</span>
                                <span style={{ fontSize: 15, fontWeight: "bolder" }}>Qty: {TotalQty}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, overflowY: "scroll", height: window.innerHeight - 280, flex: 1 }} className='hidescroll'>
                                {
                                    [...salesitem].filter((i) => i.sales_id === sales_id).map((item) => {
                                        return (
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, minHeight: 50, border: "1px solid #000", backgroundColor: "#fff", borderRadius: 5 }}>
                                                    <div style={{ width: 60, height: 60, backgroundColor: "#ddd" }}>
                                                        <img src={ENV.IMAGE+[...product].filter((i)=>Number(i.id) === Number(item.product_id)).map((i)=>i.product_image).slice(0)} style={{ width: 60, height: 60, border: "2px solid #fff", borderRadius: 5 }} />
                                                    </div>
                                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 0 }}>
                                                        <span style={{ fontWeight: "bolder" }}>{[...product].filter((i) => i.id === item.product_id).map((i)=>i.product_name).slice(0)}</span>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                            <span style={{ color: "#3e3e3e", fontSize: 12 }}>Price: {Number(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                            <span style={{ color: "#3e3e3e", fontSize: 12 }}></span>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                            <span style={{ color: "#3e3e3e", fontSize: 12 }}>Qty: {item.qty}</span>
                                                            <span style={{ color: "#3e3e3e", fontSize: 12 }}>Sub-Total: {Number(Number(item.price) * Number(item.qty)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ width: "100%", flex: 2 }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <span>Order ID:</span>
                                        <span style={{ fontWeight: "bolder" }}>{[...sales].filter((i) => i.id === sales_id).map((i) => i.id).slice(0)}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <span>Store Name:</span>
                                        <span style={{ fontWeight: "bolder" }}>{[...store].filter((i) => i.id === [...sales].filter((i) => i.id === sales_id).map((i) => i.store_id).slice(0)).map((i) => i.store_name).slice(0)}</span>
                                    </div>
                                    {
                                        [...sales].filter((i) => i.id === sales_id).map((i) => i).slice(0)[0]?.customer_name !== '' ?
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <span>Name</span>
                                                <span style={{ fontWeight: "bolder" }}>{[...sales].filter((i) => i.id === sales_id).map((i) => i.customer_name).slice(0)}</span>
                                            </div>
                                            : null
                                    }
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <span>Payment</span>
                                        <span style={{ fontWeight: "bolder" }}>{[...sales].filter((i) => i.id === sales_id).map((i) => i.payment).slice(0)}</span>
                                    </div>
                                    {
                                        [...sales].filter((i) => i.id === sales_id).map((i) => i).slice(0)[0]?.payment !== 'Cash' ? 
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <span>Reference</span>
                                            <span style={{ fontWeight: "bolder" }}>{[...sales].filter((i) => i.id === sales_id).map((i) => i.payment_reference).slice(0)}</span>
                                        </div>
                                        : null
                                    }
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <span>Date</span>
                                        <span style={{ fontWeight: "bolder" }}>{[...sales].filter((i) => i.id === sales_id).map((i) => i.date_time).slice(0)}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <span style={{ fontWeight: "bolder" }}>Grand Total</span>
                                        <span style={{ fontWeight: "bolder" }}>{Number(SubTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                </div>
                                {/* <button onClick={()=>window.location.assign('/payment')} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Payment</button> */}
                            </div>

                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default ReportDetails;
