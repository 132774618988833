import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from './ENV';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { format } from 'date-fns';

const StoreStockHistory = () => {
    const { store_id, product_id } = useParams();
    const [stock, setStock] = useState([])
    const [product, setProduct] = useState([])
    const [store, setStore] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("store_stock", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setStock(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            const arr = res.data.map(obj => ({ ...obj, "qty": 0 }))
            setProduct(arr)
        })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // const groupedData = salesitem?.reduce((groups, item) => {
    //     const { product_id } = item;
    //     // console.log(product_id)
    //     if (product_id == 0) {
    //         if (!groups[product_id]) {
    //             groups[product_id] = [];
    //         }
    //         groups[product_id].push(item);
    //     } else {
    //         if (!groups[product_id]) {
    //             groups[product_id] = [];
    //         }
    //         groups[product_id].push(item);
    //     }
    //     return groups;
    // }, {});

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 5, padding: 5}}>
            {/* {JSON.stringify(stock)} */}
            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start"}}>
                <span>Store: {[...store].filter((i)=>Number(i.id) === Number(store_id)).map((i)=>i.store_name).slice(0)}</span>
                <span>Name: <span style={{fontWeight: "bolder"}}>{[...product].filter((i)=>Number(i.id) === Number(product_id))[0]?.product_name}</span></span>
                <span>Code: {[...product].filter((i)=>Number(i.id) === Number(product_id)).map((i)=>i.product_code).slice(0)}</span>
            </div>
            {
                Object.entries([...stock]?.filter((i)=>Number(i.store_id) === Number(store_id) && Number(i.product_id) === Number(product_id)).reduce((groups, item) => {
                    const { datetime } = item;                                                            
                    if (!groups[datetime]) {
                        groups[datetime] = [];
                    }
                    groups[datetime].push(item);
                    return groups;
                }, {})).map(([datetime, item]) => {
                    return (
                        <div style={{borderRadius: 5, border: "1px solid #ddd", padding: 5, backgroundColor: "#b8d1d4"}}>
                            <div>
                                <strong style={{fontSize: 20}}>{format(new Date(datetime), 'MMMM dd, yyyy')  }</strong>
                            </div>
                            <div>
                                {
                                    [...item].map((i)=>{
                                        return (
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                                <div style={{ padding: 5, width: "50%" }}>Qty: {i.qty}</div>
                                                <div style={{ padding: 5, width: "50%", textAlign: "right" }}>{format(new Date(i.date_time), 'hh:mm a')}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* {JSON.stringify(item)} */}
                        </div>
                    )
                })
            }
        </div>
    );
}

export default StoreStockHistory;
