import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import StoreAdd from './component/store/StoreAdd';
import PanelForm from './component/store/PanelForm';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import AddStockFormPanel from './component/store/AddStockFormPanel';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const StoreStockList = ({ userInfo }) => {
    const { store_id } = useParams();
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [store, setStore] = useState([])
    const [user, setUser] = useState([])
    const [search, setSearch] = useState('')
    const [product, setProduct] = useState([])
    const [stock, setStock] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setProduct(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("allStock", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStock(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        // var formData = new FormData();
        // formData.append("user", 1);
        // await axios({
        //     method: "post",
        //     url: ENV.DOMAIN+'api/index.php',
        //     data: formData,
        // }).then((res)=>{
        //     setUser(res.data)
        // })
        // .catch((err) => {
        //     console.log(err);
        // });
    }, [ENV])

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Store Stock List" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 20, fontWeight: "bolder"}}>Store Name: {[...store].filter((i)=>Number(i.id) === Number(store_id)).map((i)=>i.store_name).slice(0)}</span>
                            {/* <span style={{fontSize: 25, fontWeight: "bolder"}}>{[...product].filter((i)=>Number(i.id) === Number(id))[0]?.product_name}</span> */}
                            {/* <span style={{fontSize: 15, fontWeight: "normal"}}>Today</span> */}
                        </div> 
                        {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 18}}>Address: {[...store].filter((i)=>Number(i.id) === Number(store_id))[0]?.store_address}</span>
                        </div>  */}
                        <div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop: 10, paddingBottom: 10, gap: 10}}>
                                <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search Product' style={{width: "100%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd"}} />
                                {/* <StoreAdd setStore={setStore} /> */}
                                {/* <div style={{width: 40, height: 40, display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}><span className='pi pi-plus-circle' style={{fontSize: 25}}></span></div> */}
                            </div>
                        </div>
                        <div>                               
                            <div style={{display: "flex", flexDirection: "column", gap: 5, overflowY: "scroll", height: window.innerHeight - 150}} className='hidescroll'>
                                {
                                    [...product].filter((i)=>{
                                        if (search!=='') {
                                            return i?.product_name?.toLowerCase().includes(search.trim().toLowerCase()) && i?.status === 'active';
                                        }   
                                        return i?.status === 'active';                                     
                                    }).map((item) => {
                                        return (
                                            <AddStockFormPanel item={item} setProduct={setProduct} store_id={store_id} stock={stock} setStock={setStock} />
                                        )
                                    }).slice(0, 20)
                                }
                            </div>
                            
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default StoreStockList;
