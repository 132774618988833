import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";
import React from 'react';
// import Homepage from "./components/Homepage";
import Login from './mobilePOS/Login'
import Dashboard from "./mobilePOS/Dashboard";
import axios from "axios";
import ENV from "./screens/ENV";
import { useEffect, useMemo, useState } from "react";
import Patient from "./screens/Patient";
import Calendar from "./screens/Calendar";
import { AppProvider } from "./screens/context/AppContext";
import Reports from "./screens/Reports";
// import firebase from "./firebase";
import io from "socket.io-client";

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { format } from "date-fns";
import PosLogin from "./pos/Login";
import PosDashboard from "./pos/Dashboard";
import PosProduct from "./pos/Product";
import PosCashier from "./pos/Cashier";

import dir from 'C:\\Windows\\System32\\drivers\\etc\\react_register.txt';
import Product from "./mobilePOS/Product";
import Cashier from "./mobilePOS/Cashier";
import Report from "./mobilePOS/Report";
import Cart from "./mobilePOS/Cart";
import Summary from "./mobilePOS/Summary";
import Payment from "./mobilePOS/Payment";
import Category from "./mobilePOS/Category";
import Customer from "./mobilePOS/Customer";
import ReportDetails from "./mobilePOS/ReportDetails";
import Store from "./mobilePOS/Store";
import Employee from "./mobilePOS/Employee";
import StoreDetails from "./mobilePOS/StoreDetails";
import ProductReport from "./mobilePOS/ProductReport";
import ProductReportTopList from "./mobilePOS/ProductReportTopList";
import StoreReportTopList from "./mobilePOS/StoreReportTopList";
import EmployeeSalesReport from "./mobilePOS/EmployeeSalesReport";
import StoreStockList from "./mobilePOS/StoreStockList";
import StoreStockHistory from "./mobilePOS/StoreStockHistory";
import Inventory from "./mobilePOS/Inventory";
// import Lottery from "./mobilePOS/Lottery";
// import Wallet from "./mobilePOS/Wallet";
// import Register from "./mobilePOS/Register";
// import Account from "./mobilePOS/Account";
// import Administrator from "./mobilePOS/Administrator";
// import Member from "./mobilePOS/Member";
// import Commission from "./mobilePOS/Commission";
// import Transactions from "./mobilePOS/Transactions";
// import AdminMember from "./mobilePOS/AdminMember";
// import AdminBetPlay from "./mobilePOS/AdminBetPlay";
// import RequestList from "./mobilePOS/RequestList";
// import Payout from "./mobilePOS/Payout";
// import AdminPayoutRequest from "./mobilePOS/AdminPayoutRequest";
// import Result from "./mobilePOS/Result";

function App() {
    // localStorage.removeItem("computerKey")

    disableReactDevTools();      
    // const socket = io.connect("http://192.168.1.11:3001");

    const position = sessionStorage.getItem('position');

    const [computerAddress, setComputerAddress] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [notification, setNotification] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [timeout, settimeout] = useState(0);
    const [newtransaction, setNewTransaction] = useState([])




    // const joinRoom = () => {
    //     socket.emit("join_room", '546546546')
    // }
    // useEffect(async()=>{     

    //     var formData = new FormData();
    //     formData.append("profile", 1);
    //     formData.append("user_id", sessionStorage.getItem('user_id'));

    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         // console.log(res.data)
    //         if(res.data[0]) {
    //             setUserInfo(res.data[0])
    //             setisLoading(false)
    //         } 
    //         // else {                
    //         //     setisLoading(false)
    //         //     sessionStorage.removeItem('user_id')
    //         //     sessionStorage.removeItem('username')
    //         // }
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // },[setUserInfo]);

 

    useEffect(()=>{    
        // socket.emit("clinic_data", {room: 123})
        // socket.on("receive_data", (data) => {
        //     if(data != null){                    
        //         console.log(data)
        //         setNewTransaction(data)
        //     }
        // })

        fetch(dir)
        .then(row => row.text())
        .then(text => {
            // setComputerAddress(text)
            if (text !== localStorage.getItem("computerKey")) {
                // console.log('exit'+text)
                localStorage.setItem("computerKey", text);
                return;
            }
            // console.log('renew')
            localStorage.setItem("computerKey", text);
        });


    }, [dir])

    // console.log(localStorage.getItem("computerKey"))
    
    // if (localStorage.getItem("computerKey") !== 'tubillara') {
    //     return (
    //         <div style={{
    //             width:"100vw", 
    //             height:"100vh", 
    //             display:"flex", 
    //             flexDirection: "row", 
    //             justifyContent:"center", 
    //             alignItems:"center",
    //             fontSize: 60,
    //             fontWeight: 600,
    //             color: "rgb(255 124 124)",
    //             textShadow: "0 0 3px #000, 0 0 5px #000"
    //         }}>
    //             Device not allowed!
    //         </div>
    //     )
    // }
    // console.log(sessionStorage.getItem('username'))
    return (
        <AppProvider>
            <Router>
                <Switch>
                    <Route exact={true} path="/">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                            sessionStorage.getItem('position') === 'admin' ? <Dashboard /> : <Cashier />
                        }
                    </Route>
                    <Route exact={true} path="/dashboard">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> :
                                sessionStorage.getItem('position') === 'admin' ? <Dashboard /> : null
                        }
                    </Route>
                    <Route exact={true} path="/customer">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> :
                                sessionStorage.getItem('position') === 'admin' ? <Customer /> : null
                        }
                    </Route>
                    <Route exact={true} path="/cashier">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Cashier />
                        }
                    </Route>
                    <Route exact={true} path="/cart">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Cart />
                        }
                    </Route>
                    <Route exact={true} path="/payment">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Payment />
                        }
                    </Route>
                    <Route exact={true} path="/category">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Category /> : null
                        }
                    </Route>
                    <Route exact={true} path="/product">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Product /> : null
                        }
                    </Route>
                    <Route exact={true} path="/product/report/:id">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <ProductReport /> : null
                        }
                    </Route>
                    <Route exact={true} path="/summary">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Summary /> : null
                        }
                    </Route>
                    <Route exact={true} path="/store">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Store /> : null
                        }
                    </Route>
                    <Route exact={true} path="/store/details/:store_id">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <StoreDetails /> : null
                        }
                    </Route>
                    <Route exact={true} path="/store/stock/list/:store_id">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <StoreStockList /> : null
                        }
                    </Route>
                    <Route exact={true} path="/store/stock/history/:store_id/:product_id">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <StoreStockHistory /> : null
                        }
                    </Route>
                    <Route exact={true} path="/employee">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Employee /> : null
                        }
                    </Route>
                    <Route exact={true} path="/employee/sales/report/:id">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <EmployeeSalesReport /> : null
                        }
                    </Route>
                    <Route exact={true} path="/inventory">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Inventory /> : null
                        }
                    </Route>
                    <Route exact={true} path="/inventory/:id">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Inventory /> : null
                        }
                    </Route>
                    <Route exact={true} path="/report">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Report /> : null
                        }
                    </Route>
                    <Route exact={true} path="/report/details/:sales_id">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <ReportDetails /> : null
                        }
                    </Route>
                    <Route exact={true} path="/report/product/toplist">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <ProductReportTopList /> : null
                        }
                    </Route>
                    <Route exact={true} path="/report/store/toplist">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <StoreReportTopList /> : null
                        }
                    </Route>
                    
                    {/* <Route exact={true} path="/lottery">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : 
                             sessionStorage.getItem('position') === 'admin' ? <Lottery /> : null
                        }
                    </Route>
                    <Route exact={true} path="/wallet">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Wallet />
                        }
                    </Route>
                    <Route exact={true} path="/wallet/payout">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Payout />
                        }
                    </Route>
                    <Route exact={true} path="/account">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Account />
                        }
                    </Route>
                    <Route exact={true} path="/member">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Member />
                        }
                    </Route>
                    <Route exact={true} path="/commission">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Commission />
                        }
                    </Route>
                    <Route exact={true} path="/transactions">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Transactions />
                        }
                    </Route>
                    <Route exact={true} path="/result">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Result />
                        }
                    </Route>
                    <Route exact={true} path="/register/:user_id">
                        {
                            <Register />
                        }
                    </Route>
                    <Route exact={true} path="/administrator">
                        <Administrator/>
                    </Route>
                    <Route exact={true} path="/administrator/bet-player">
                        <AdminBetPlay/>
                    </Route>
                    <Route exact={true} path="/administrator/bet-player/review/:draw_time">
                        <AdminBetPlay/>
                    </Route>
                    <Route exact={true} path="/administrator/member">
                        <AdminMember/>
                    </Route>
                    <Route exact={true} path="/administrator/payout/request">
                        <AdminPayoutRequest/>
                    </Route> */}
                </Switch>
            </Router>
        </AppProvider>
    )
}

export default App;
