import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { Toast } from 'primereact/toast';

const SetLoginAccess = (props) => {
    const toast = useRef(null);
    const [inputvalue, setInputValue] = useState({ ...props.item })
    const [openDialog, setOpenDialog] = useState({ open: false })
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }
    const [store, setStore] = useState([])
    const [user, setUser] = useState([])

    console.log(inputvalue)

    // useMemo(async()=> {
    //     var formData = new FormData();
    //     formData.append("user", 1);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'api/index.php',
    //         data: formData,
    //     }).then((res)=>{
    //         setUser(res.data)
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const setAccount = async (item) => {
        if (props.item.username !== '') {
            var formData = new FormData();
            formData.append("setAccount", 1);
            formData.append("inputvalue", JSON.stringify(inputvalue));
            await axios({
                method: "post",
                url: ENV.DOMAIN+'api/index.php',
                data: formData,
            }).then((res)=>{
                console.log(res.data)
                hideDialog()
                toast.current.show({severity:'success', summary: 'Success', detail:'Successfully Saved', life: 3000});
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            if (inputvalue.username !== '' && inputvalue.password !== '') {
                var formData = new FormData();
                formData.append("setAccount", 1);
                formData.append("inputvalue", JSON.stringify(inputvalue));
                await axios({
                    method: "post",
                    url: ENV.DOMAIN+'api/index.php',
                    data: formData,
                }).then((res)=>{
                    console.log(res.data)
                    hideDialog()
                    toast.current.show({severity:'success', summary: 'Success', detail:'Successfully Saved', life: 3000});
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        }
    }

    return (
        <div>
            <Toast ref={toast} />

            <Sidebar
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 400, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label>Assign Store</label>
                            <select name='store_id' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={[...store].filter((i)=>Number(i.id) === Number(inputvalue.store_id))[0]?.id} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }}>
                                <option>Select Store</option>
                                {
                                    [...store].map((item)=>{
                                        return (
                                            <option value={item.id}>{item.store_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        {
                            props.item.username !== '' ?
                            null
                            : 
                            <div>
                                <label>Username</label>
                                <input name='username' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.username} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                            </div>

                        }
                        <div>
                            <label>Password</label>
                            <input name='password' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} style={{ width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                        {
                            props.item.username !== '' ?
                            <button onClick={()=>setAccount()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Update</button>
                            :
                            <button onClick={()=>setAccount()} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Create Account</button>
                        }
                    </div>
            </Sidebar>
            {
                props.item.username !== '' ?
                <button onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Update Account</button>
                :
                <button onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#374151", color: "#fff" }}>Create Account</button>
            }
        </div>
    );
}

export default SetLoginAccess;
