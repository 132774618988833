import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import ProductCard from './component/product/ProductCard';
import ProductAdd from './component/product/ProductAdd';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Product = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [product, setProduct] = useState([])
    const [search, setSearch] = useState('')
    const [category, setCategory] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("category", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setCategory(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const loadproduct = async () => {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setProduct(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }
    
    useEffect(()=>{
        loadproduct()
    }, [])

    console.log(product)



    // useEffect(()=>{ // Time
    //     const myInterval = setInterval(()=>{
    //         setSec(sec+1);
    //         if (sec === 59) {
    //             setMin(min+1)
    //             setSec(0);
    //         }
    //         if (min === 59) {
    //             setHr(hr+1)
    //             setMin(0)
    //             setSec(0);
    //         }
    //             setCount(count-1)
    //     }, 1000);
    //     console.log(hr+' : '+min+' : '+sec);
    //     return () => clearInterval(myInterval)
    // }, [count]);


    // console.log(window.innerWidth <= 768)
    // function domReady(fn) {
    //     if (document.readyState === "complete" || document.readyState === "interactive") {
    //         setTimeout(fn, 1);
    //     } else {
    //         document.addEventListener("DOMContentLoaded", fn);
    //     }
    // }

    // domReady(function () {
    //     var myqr = document.getElementById("you-qr-result");
    //     var lastResult, countResults = 0;
    //     function onScanSuccess(decodeText, decodeResult) {
    //         if (decodeText !== lastResult) {
    //             ++countResults;
    //             lastResult = decodeText;

    //             alert("you Qr is: " + decodeText, decodeResult);
    //             myqr.innerHTML = `you scan ${countResults} : ${decodeText}`;

    //         }
    //     }
    //     var htmlscanner = new Html5QrcodeScanner("preview", { fps: 10, qrbox: 250 })

    //     htmlscanner.render(onScanSuccess)
    // })

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Product" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        <div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop: 10, paddingBottom: 10}}>
                                <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search Product' style={{width: "90%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd"}} />
                                <div style={{width: 40, height: 40, display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}>
                                    <ProductAdd product={product} setProduct={setProduct} />
                                </div>
                            </div>
                        </div>
                        <div>                         
                            <div style={{display: "flex", flexDirection: "column", gap: 5, overflowY: "scroll", height: window.innerHeight - 150}} className='hidescroll'>
                                {
                                    [...product].filter((i)=>{
                                        return i?.product_name.toLowerCase().includes(search.trim().toLowerCase()) && i?.status === 'active'
                                    }).map((item, key) => {
                                        return (
                                            <ProductCard key={key} item={item} product={product} setProduct={setProduct} category={category} loadproduct={loadproduct} />
                                        )
                                    }).slice(0, 30)
                                }
                            </div>
                            
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Product;
