import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import { HelmetProvider } from 'react-helmet-async';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';                                   // css utility
// import './index.css';
import './flag.css';
import App from './App';
import AppSureball from './AppSureball';
import AppMobilePOS from './AppMobilePOS';
import AppStgcADC from './AppStgcADC';

// import reportWebVitals from './reportWebVitals';
// import reducer, { initialState } from './screens/pluggedin_2_0/reducer';
// import { StateProvider } from './screens/pluggedin_2_0/StateProvider';



ReactDOM.render(
  <>
    {/* <HelmetProvider> */}
      {/* <StateProvider initialState={initialState} reducer={reducer}> */}
        {/* <App /> */}
        {/* <AppSureball />y */}
        <AppMobilePOS />
        {/* <AppStgcADC /> */}
      {/* </StateProvider> */}
    {/* </HelmetProvider> */}
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
