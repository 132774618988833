import React from 'react';

const InventoryCard = (props) => {
    return (
        <div>
            
            <div onClick={()=>window.location.assign(`/inventory/${props.item.id}`)}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#fff", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, border: "1px solid #000", borderRadius: 5 }}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                        {/* <span style={{fontWeight: "bolder"}}>{item.customer_name}</span> */}
                        <span style={{color: "#3e3e3e", fontSize: 12}}>Store Name: {props.item.store_name}</span>
                        {/* <span style={{padding: 5, fontSize: 15, width: 100, height: 20, border: "1px solid #ddd", borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>stock-in</span> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InventoryCard;
