import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';

const PanelForm = (props) => {
    const [inputvalue, setInputValue] = useState({...props.item})
    const [openDialog, setOpenDialog] = useState({ open: false })
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const addProductBtn = async () => {
        var formData = new FormData();
        formData.append("add_store", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setStore([...res.data])
            setInputValue({"store_name":"", "address":""})
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const updateProductBtn = async () => {
        var formData = new FormData();
        formData.append("update_store", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setStore([...res.data])
            setInputValue({"store_name":"", "address":""})
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }



    return (
        <>

        <Sidebar
            icons={
                <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                    Menu
                </div>
            }
            blockScroll={true}
            position='bottom'
            draggable={false}
            style={{ width: '100rem', fontSize: 15, height: 200 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <button onClick={()=>window.location.assign(`/store/details/${props.item.id}`)} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Store Details</button>
                    <button onClick={()=>window.location.assign(`/store/stock/list/${props.item.id}`)} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Stocks</button>
                </div>
                {/* {JSON.stringify(props.item)} */}
        </Sidebar>
           {
            <div onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#fff", alignItems: "flex-start", gap: 5, width: "100%", padding: 5, border: "1px solid #000", borderRadius: 5 }}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                        {/* <span style={{fontWeight: "bolder"}}>{item.customer_name}</span> */}
                        <span style={{color: "#3e3e3e", fontSize: 12}}>Store Name: {props.item.store_name}</span>
                        {/* <span style={{padding: 5, fontSize: 15, width: 100, height: 20, border: "1px solid #ddd", borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>stock-in</span> */}
                    </div>
                </div>
            </div>
           }
        </>
    );
}

export default PanelForm;
