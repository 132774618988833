import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import ReportCard from './component/report/ReportCard';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import CanvasJSReact from '@canvasjs/react-charts';
import { format, parse } from 'date-fns';
import moment from 'moment';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const StoreDetails = ({ userInfo }) => {
    const history = useHistory()
    const { store_id } = useParams();
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [store, setStore] = useState([])
    const [sales, setSales] = useState([])
    const [salesreport, setSalesReport] = useState([])
    const [salesitem, setSalesItem] = useState([])
    const [user, setUser] = useState([])
    const [product, setProduct] = useState([])
    const [search, setSearch] = useState('')
    const [monthlysales, setMonthlySales] = useState([])
    const [dailysales, setDailySales] = useState([])


    const [clickdailysales, setClickDailySales] = useState({})
    const [clickmonthlysales, setClickMonthlySales] = useState({})

    useMemo(async () => {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setStore(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setSales(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("sales_report", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setSalesReport(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("salesitem", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setSalesItem(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setProduct(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("user", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            setUser(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("store_daily_sales", 1);
        formData.append("inputvalue", JSON.stringify({"store_id": store_id}));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setDailySales(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("store_monthly_sales", 1);
        formData.append("store_id", store_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/index.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setMonthlySales(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])
    
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    

    const onClickDaily = (e) => {
        setClickDailySales(e.dataPoint)
        // console.log(e, e.dataPoint)
    }
    const onClickMonthly = (e) => {
        setClickMonthlySales(e.dataPoint)
    }

    // console.log(clickdailysales)

    const DailySalesData = {
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        zoomEnabled: true,
        title: {
            fontSize: 15,
            text: "Daily Sales"
        },
        axisX: {
            margin: 10,
            labelPlacement: "inside",
            tickPlacement: "inside"
        },
        axisY: {
            // title: "(in USD)",
            prefix: "₱"
        },
        data: [{
            type: "column",
            click: onClickDaily,
            yValueFormatString: "₱##,###.##",
            dataPoints: [...dailysales]
        }]
    }

    const MonthlySalesData = {
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        zoomEnabled: true,
        title: {
            fontSize: 15,
            text: "Monthly Sales"
        },
        axisX: {
            margin: 10,
            labelPlacement: "inside",
            tickPlacement: "inside"
        },
        axisY: {
            // title: "(in USD)",
            prefix: "₱"
        },
        data: [{
            type: "column",
            click: onClickMonthly,
            yValueFormatString: "₱##,###.##",
            dataPoints: [...monthlysales]
        }]
    }



    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Store Details" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">

                    <div className="container-fluid">
                        {/* <div>
                            <div>
                                <span style={{fontSize: 25, fontWeight: "bolder"}}>Stores</span>
                            </div>
                            <div style={{ width: "100%", overflowX: "scroll", marginBottom: 0, paddingBottom: 0 }} className='hidescroll'>
                                <div style={{ display: "flex", gap: 5, marginBottom: 0 }}>
                                    {
                                        [...store].map((i) => {
                                            return (
                                                <div>
                                                    <div style={{ width: 100, height: 150, border: "1px solid #000", borderRadius: 5 }}>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <br /> */}
                        <div>
                            {/* <div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop: 10, paddingBottom: 10 }}>
                                    <input type='date' onChange={(e) => setSearch(e.target.value)} placeholder='Search Date' style={{ width: "100%", padding: 5, height: 40, borderRadius: 5, border: "1px solid #ddd" }} />
                                </div>
                            </div> */}
                            <div style={{textAlign: "center"}}>
                                <span>Store Report</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                <span>Store Name: <span style={{fontWeight: "bolder"}}>{[...store].filter((i) => i.id === store_id)[0]?.store_name}</span></span>
                                <span>Store Address: {[...store].filter((i) => i.id === store_id)[0]?.store_address}</span>
                                {/* <span>Date Report: {search}</span> */}
                                {/* <span>Net: {[...store].filter((i)=>i.id === store_id)[0]?.store_address}</span> */}
                            </div>
                            <hr />
                            <div>
                                <CanvasJSChart options={DailySalesData} containerProps={{ width: '100%', height: '300px' }} />
                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                    {
                                       clickdailysales.label ?
                                        <>
                                            <span>{clickdailysales.label === format(new Date(), 'yyyy-MM-dd') ? "Today" : clickdailysales.label} Revenue: <span style={{fontWeight: "bolder"}}>{[...dailysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === clickdailysales.label).reduce((amount, item) => amount = (item.y) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                            <span>{clickdailysales.label === format(new Date(), 'yyyy-MM-dd') ? "Today" : clickdailysales.label} Profit: <span style={{fontWeight: "bolder"}}>{[...dailysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === clickdailysales.label).reduce((amount, item) => amount = Number(item.y)-Number(item.cost) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                        </>
                                        : 
                                        <>
                                            <span>Today Revenue: <span style={{fontWeight: "bolder"}}>{[...dailysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === format(new Date(), 'yyyy-MM-dd')).reduce((amount, item) => amount = (item.y) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                            <span>Today Profit: <span style={{fontWeight: "bolder"}}>{[...dailysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === format(new Date(), 'yyyy-MM-dd')).reduce((amount, item) => amount = Number(item.y)-Number(item.cost) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                            <hr />
                            <div>
                                <CanvasJSChart options={MonthlySalesData} containerProps={{ width: '100%', height: '300px' }} />
                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                    {
                                        clickmonthlysales.label ?
                                            <>
                                                <span>{clickmonthlysales.label === format(new Date(), 'MMMM') ? "this Month" : clickmonthlysales.label} Revenue: <span style={{ fontWeight: "bolder" }}>{[...monthlysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === format(new Date(), 'MMMM')).reduce((amount, item) => amount = (item.y) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                <span>{clickmonthlysales.label === format(new Date(), 'MMMM') ? "this Month" : clickmonthlysales.label} Profit: <span style={{ fontWeight: "bolder" }}>{[...monthlysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === format(new Date(), 'MMMM')).reduce((amount, item) => amount = Number(item.y) - Number(item.cost) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                            </>
                                            :
                                            <>
                                                <span>this Month Revenue: <span style={{ fontWeight: "bolder" }}>{[...monthlysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === format(new Date(), 'MMMM')).reduce((amount, item) => amount = (item.y) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                <span>this Month Profit: <span style={{ fontWeight: "bolder" }}>{[...monthlysales].filter((i) => Number(i.store_id) === Number(store_id) && i.label === format(new Date(), 'MMMM')).reduce((amount, item) => amount = Number(item.y) - Number(item.cost) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                            </>
                                    }
                                </div>
                            </div>
                            <hr />
                            <div>
                                <span style={{ fontSize: 15, fontWeight: "bolder" }}>Sales Report</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                {
                                    [...sales].filter((i) => {
                                        return i?.store_id === store_id
                                    }).map((item) => {
                                        return (
                                            <ReportCard item={item} user={user} store={store} salesitem={salesitem} />
                                        )
                                    }).slice(0, 10)
                                }
                            </div>
                            <hr />
                            <div>
                                <span style={{ fontSize: 15, fontWeight: "bolder" }}>Product Sales Daily Report</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                            {
                                    [...salesreport].map((item)=>{
                                        if ([...item.list].filter((i)=>Number(i.store_id) === Number(store_id)).map((items)=>items).length > 0) {
                                            return (
                                                <div>
                                                    <div style={{fontWeight: "bolder"}}>
                                                        {/* {item.date} */}
                                                        {format(new Date(item.date), 'MMMM dd, yyyy')}
                                                    </div>
                                                    <div>
                                                        {
                                                            [...item.list].filter((i)=>Number(i.store_id) === Number(store_id)).map((items)=>{
                                                                return (
                                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd"}}>
                                                                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 5}}>
                                                                            {format(parse(items.timeonly.split('.')[0], 'HH:mm:ss', new Date), 'hh:mm:ss a')}
                                                                        </div>
                                                                        <div style={{flex: 1, borderLeft: "1px solid #ddd", borderRight: "1px solid #ddd", display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 5}}>{[...product].filter((i)=>Number(i.id) === Number(items.product_id)).map((i)=>i.product_name).slice(0)}</div>
                                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 0}}>
                                                                            <div style={{width: 50, textAlign: "center", fontSize: 10}}>
                                                                                <div>{ items.price }</div>
                                                                                <div>{ items.qty }</div>
                                                                            </div>
                                                                            <div style={{width: 70, textAlign: "right", fontSize:12, padding: 5}}>{ (Number(items.qty) * Number(items.price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #000"}}>
                                                        <div></div>
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 0}}>
                                                            <div style={{width: 50, border: "1px solid #ddd", textAlign: "center", fontSize:12, padding: 5}}>{[...item.list].filter((i)=>Number(i.store_id) === Number(store_id)).reduce((qty, i) => qty = Number(i.qty) + Number(qty), 0)}</div>
                                                            <div style={{width: 70, border: "1px solid #ddd", textAlign: "right", fontSize:12, padding: 5, fontWeight: "bolder"}}>{[...item.list].filter((i)=>Number(i.store_id) === Number(store_id)).reduce((amount, i) => amount = (i.price * i.qty) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {/* {
                                    [...salesitem].filter((i) => i.store_id === store_id).map((item) => {
                                        return (

                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <div style={{ flex: 1, border: "1px solid #ddd", fontSize: 12, height: 30, display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 5 }}>{[...product].filter((i) => Number(i.id) === Number(item.product_id))[0]?.product_name}</div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 0 }}>
                                                    <div style={{ width: 50, border: "1px solid #ddd", textAlign: "center", fontSize: 10, height: 30 }}>
                                                        <div>{item.price}</div>
                                                        <div>{item.qty}</div>
                                                    </div>
                                                    <div style={{ width: 70, border: "1px solid #ddd", textAlign: "right", height: 30, fontSize: 12, padding: 5 }}>{(Number(item.qty) * Number(item.price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                </div>
                                            </div>
                                        )
                                    }).slice(0,10)
                                } */}
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default StoreDetails;
