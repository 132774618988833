import React from 'react';

const Navbar = ({ userInfo }) => {
    const position = sessionStorage.getItem('position');
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
                {/* <span className="brand-text font-weight-light">Clinic</span> */}
                <span className="brand-text font-weight-light">SYSTEM</span>
            </a>
            {/* Sidebar */}
            <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition"><div className="os-resize-observer-host observed"><div className="os-resize-observer" style={{ left: 0, right: 'auto' }} /></div><div className="os-size-auto-observer observed" style={{ height: 'calc(100% + 1px)', float: 'left' }}><div className="os-resize-observer" /></div><div className="os-content-glue" style={{ margin: '0px -8px', width: 249, height: 528 }} /><div className="os-padding"><div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}><div className="os-content" style={{ padding: '0px 8px', height: '100%', width: '100%' }}>
                {/* Sidebar user panel (optional) */}
                {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="info">
                        <a className="d-block">BRANCH NAME</a>
                    </div>
                </div> */}
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                        {
                            sessionStorage.getItem('position') === 'admin' ?
                            <li className="nav-item">
                                <a href="/dashboard" className="nav-link">
                                    <p>Dashboard</p>
                                </a>
                            </li>
                            : null
                        }
                        <li className="nav-item">
                            <a href="/cashier" className="nav-link">
                                <p>Cashier</p>
                            </a>
                        </li>
                        {
                            sessionStorage.getItem('position') === 'admin' ?
                            <li className="nav-item">
                                <a href="/category" className="nav-link">
                                    <p>Category</p>
                                </a>
                            </li>
                            : null
                        }
                        {
                            sessionStorage.getItem('position') === 'admin' ?
                            <li className="nav-item">
                                <a href="/product" className="nav-link">
                                    <p>Product</p>
                                </a>
                            </li>
                            : null
                        }
                        {
                            sessionStorage.getItem('position') === 'admin' ?
                            <li className="nav-item">
                                <a href="/store" className="nav-link">
                                    <p>Store</p>
                                </a>
                            </li>
                            : null
                        }
                        {
                            sessionStorage.getItem('position') === 'admin' ?
                            <li className="nav-item">
                                <a href="/employee" className="nav-link">
                                    <p>Employee</p>
                                </a>
                            </li>
                            : null
                        }
                        {
                            sessionStorage.getItem('position') === 'admin' ?
                            <li className="nav-item">
                                <a href="/inventory" className="nav-link">
                                    <p>Inventory</p>
                                </a>
                            </li>
                            : null
                        }
                        {
                            sessionStorage.getItem('position') === 'admin' ?
                            <li className="nav-item">
                                <a href="/report" className="nav-link">
                                    <p>Report</p>
                                </a>
                            </li>
                            : null
                        }
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div></div></div><div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ width: '100%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ height: '38.9257%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar-corner" /></div>
            {/* /.sidebar */}
        </aside>

    );
}

export default Navbar;
