import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import StoreAdd from './component/store/StoreAdd';
import PanelForm from './component/store/PanelForm';
import InventoryCard from './component/inventory/InventoryCard';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Inventory = ({ userInfo }) => {
    const history = useHistory()
    const { id } = useParams();
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [store, setStore] = useState([])
    const [user, setUser] = useState([])
    const [search, setSearch] = useState('')
    const [inventory, setInventory] = useState([])
    const [product, setProduct] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("user", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("inventorylist", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setInventory(res.data)
            console.log(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setProduct(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                <Header titleheader="Inventory" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        <div>                               
                            <div style={{display: "flex", flexDirection: "column", gap: 5, overflowY: "scroll", height: window.innerHeight - 150}} className='hidescroll'>
                                {
                                    id ?
                                    Object.entries([...inventory]?.filter((i)=>Number(i.store_id) === Number(id)).reduce((groups, item) => {
                                        const { datetime } = item;                                                            
                                        if (!groups[datetime]) {
                                            groups[datetime] = [];
                                        }
                                        groups[datetime].push(item);
                                        return groups;
                                    }, {})).map(([datetime, item])=>{
                                        const totalCost = [...item]?.reduce((amount, item) => amount = Number(item.count) * Number(item.unit_cost) + amount, 0);
                                        const TotalCount = [...item]?.reduce((amount, item) => amount = Number(item.count) + Number(amount), 0);
                                        return (
                                            <div style={{borderRadius: 5, border: "1px solid #ddd"}}>
                                                {/* {JSON.stringify(item)} */}
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                                                    <div>
                                                        <div style={{fontSize: 10}}>Stock Count Date</div>
                                                        <div style={{fontSize: 10}}>{datetime}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{fontSize: 10}}>Total Counted Item</div>
                                                        <div style={{fontSize: 10}}>{TotalCount}</div>
                                                    </div>
                                                    
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #ddd", color: "#000", backgroundColor: "#ddd", padding: 5}}>
                                                    <div style={{fontSize: 10}}>Description</div>
                                                    <div style={{display:"flex", flexDirection: "row", gap: 10, justifyContent: "flex-end"}}>
                                                        <div style={{fontSize: 10, width: 100, textAlign: "right"}}>On-Hand</div>
                                                        <div style={{fontSize: 10, width: 100, textAlign: "right"}}>Cost</div>
                                                    </div>
                                                </div>
                                                {
                                                    [...item].map((items)=>{
                                                        return (
                                                            <div style={{borderBottom: "1px solid #ddd", padding: 5}}>
                                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                                                                    {/* {items.product_id} */}
                                                                    <div style={{fontSize: 10, width: "60%"}}>
                                                                        {
                                                                            [...product].filter((i)=>Number(i.id) === Number(items.product_id))[0]?.product_name
                                                                        }
                                                                    </div>
                                                                    <div style={{display:"flex", flexDirection: "row", gap: 10, justifyContent: "flex-end"}}>
                                                                        <div style={{fontSize: 10, width: 100, textAlign: "right"}}>{items.count}</div>
                                                                        <div style={{fontSize: 10, width: 100, textAlign: "right"}}>{items.unit_cost}</div>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                                                                    {/* {items.product_id} */}
                                                                    <div style={{fontSize: 10, width: "60%"}}></div>
                                                                    <div style={{display:"flex", flexDirection: "row", gap: 10, justifyContent: "flex-end"}}>
                                                                        <div style={{fontSize: 10, width: 100, textAlign: "right"}}></div>
                                                                        <div style={{fontSize: 10, width: 100, textAlign: "right"}}>{Number(items.unit_cost*items.count)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 5}}>
                                                    <div style={{fontSize: 10}}></div>
                                                    <div style={{fontSize: 10}}></div>
                                                    <div style={{fontSize: 10}}>
                                                        <div>{totalCost}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : 
                                    [...store].filter((i)=>{
                                        return i?.store_name?.toLowerCase().includes(search.trim().toLowerCase())
                                    }).map((item) => {
                                        return (
                                            <InventoryCard item={item} />
                                        )
                                    })
                                }
                            </div>
                            
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
        </div>

    );
}

export default Inventory;
