import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useRef, useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ENV from '../../ENV';

const ProductScan = (props) => {
    const [product, setProduct] = useState({})
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({ qty: 0 })

    const index = props.cartlist.findIndex((i) => Number(i?.id) === Number(product?.id));

    console.log(index, product, props.cartlist, inputvalue)
    
    const invalid = useRef()

    const onAddCart = () => {
        if (inputvalue.qty > 0) {
            if (Number(props.cartlist[index]?.id) === Number(product?.id)) {
                alert(1)
                props.cartlist[index].qty = Number(inputvalue.qty);
                sessionStorage.setItem("cart_list", JSON.stringify([...props.cartlist]))
                props.setCartList([...props.cartlist])
                hideDialog()
            } else {
                alert(2)
                sessionStorage.setItem("cart_list", JSON.stringify([...props.cartlist, { ...product, qty: Number(inputvalue.qty), "user_id": sessionStorage.getItem('user_id') }]))
                props.setCartList([...props.cartlist, { ...product, qty: Number(inputvalue.qty), "user_id": sessionStorage.getItem('user_id') }])
                hideDialog()
            }
        } else {
            // alert("qty error")
            invalid.current.style.border = "1px solid red";
        }
    }

    const increase = async () => {
        if (!index) {
            props.cartlist[index].qty = (props.cartlist[index]?.qty) + Number(1);
            setInputValue(prev => ({ ...prev, qty: Number(props.cartlist[index]?.qty) }))
        } else {
            setInputValue(prev => ({ ...prev, qty: Number(1)+Number(prev.qty) }))
        }
    }

    // console.log(props.cartlist[index]?.id, inputvalue)

    const decrease = async () => {
        if (inputvalue.qty > 0) {
            if (!index) {
                props.cartlist[index].qty = (props.cartlist[index]?.qty) - Number(1);
                setInputValue(prev => ({ ...prev, qty: Number(props.cartlist[index]?.qty) }))
            } else {
                setInputValue(prev => ({ ...prev, qty: Number(prev.qty - 1) }))
            }
        }
    }

    const ShowDialog = () => {
        // setInputValue(prev => ({ ...prev, qty: 0 }))
        setOpenDialog(prev => ({ ...prev, open: true }))
    }

    const hideDialog = (event) => {
        setProduct({})
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    useEffect(()=>{
        if (Number(props.cartlist[index]?.id) === Number(product.id)) {
            setInputValue(prev => ({ ...prev, qty: Number(props.cartlist[index]?.qty) }))
        } else {
            setInputValue(prev => ({ ...prev, qty: 0 }))
        }
    }, [index, props])

    return (
        <>

            <Sidebar
                showCloseIcon={false}
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                        
                    </div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 500, fontSize: 15 }} //, height: 500
                visible={openDialog.open}
                onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>   
                        <BarcodeScannerComponent
                        width={"100%"}
                        // height={"100%"}
                        // torch={true}
                        onUpdate={async(err, result) => {
                            if (result) {
                                // props.setSearch(result.text)
                                const data = props.product.filter((i)=>i.product_code === result.text);
                                if (data.length>0) {
                                    setProduct({...data[0]});
                                } else {
                                    console.log("Error")
                                }
                            }
                        }}
                        />
                        {
                            product.id > 0 ?
                            <>
                                <div>
                                    <div>
                                        <span>Name: {product.product_name}</span>
                                    </div>
                                    <div>
                                        <span>Code: {product.product_code}</span>
                                    </div>
                                    {/* <div>
                                        <span>Cat:</span>
                                    </div> */}
                                    <div>
                                        <span>Price: {product.price}</span>
                                    </div>
                                    
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                        <span onClick={(e) => increase()} className='pi pi-plus-circle' style={{ fontSize: 30 }}></span>
                                        <input ref={invalid} type='number' name='qty' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))} value={inputvalue.qty} style={{ width: 70, height: 40, borderRadius: 5, border: "1px solid #ddd", textAlign: "center" }} />
                                        <span onClick={(e) => decrease()} className='pi pi-minus-circle' style={{ fontSize: 30 }}></span>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                    <button onClick={()=>onAddCart()} className='btn btn-sm btn-info'>Add to Basket</button>
                                </div>
                            </>
                            :null
                        }
                </div>
            </Sidebar>



            <div onClick={() => ShowDialog()} style={{width: 40, height: 40, display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}><span className='pi pi-qrcode' style={{fontSize: 25}}></span></div>
        </>
    );
}

export default ProductScan;
