import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import ReportCard from './component/report/ReportCard';
import CanvasJSReact from '@canvasjs/react-charts';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Dashboard = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [store, setStore] = useState([])
    const [sales, setSales] = useState([])
    const [salesitem, setSalesItem] = useState([])
    const [user, setUser] = useState([])
    const [overalldailysales, setOverAllDailySales] = useState([])
    const [monthlysales, setMonthlySales] = useState([])
    // console.log(window.navigator.permissions)
    // useMemo(async()=> {
    //     await axios.get(ENV.DOMAIN+'api/?getMacAdd').then((res)=>{
    //         console.log(res.data)
    //         alert(JSON.stringify(res.data))
    //     })
    // }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("getMacAdd", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            // alert(JSON.stringify(res.data))
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sales_item", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSalesItem(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("user", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("overall_daily_sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setOverAllDailySales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("monthly_sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setMonthlySales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])



    // useEffect(()=>{ // Time
    //     const myInterval = setInterval(()=>{
    //         setSec(sec+1);
    //         if (sec === 59) {
    //             setMin(min+1)
    //             setSec(0);
    //         }
    //         if (min === 59) {
    //             setHr(hr+1)
    //             setMin(0)
    //             setSec(0);
    //         }
    //             setCount(count-1)
    //     }, 1000);
    //     console.log(hr+' : '+min+' : '+sec);
    //     return () => clearInterval(myInterval)
    // }, [count]);


    // console.log(window.innerWidth <= 768)
    // function domReady(fn) {
    //     if (document.readyState === "complete" || document.readyState === "interactive") {
    //         setTimeout(fn, 1);
    //     } else {
    //         document.addEventListener("DOMContentLoaded", fn);
    //     }
    // }

    // domReady(function () {
    //     var myqr = document.getElementById("you-qr-result");
    //     var lastResult, countResults = 0;
    //     function onScanSuccess(decodeText, decodeResult) {
    //         if (decodeText !== lastResult) {
    //             ++countResults;
    //             lastResult = decodeText;

    //             alert("you Qr is: " + decodeText, decodeResult);
    //             myqr.innerHTML = `you scan ${countResults} : ${decodeText}`;

    //         }
    //     }
    //     var htmlscanner = new Html5QrcodeScanner("preview", { fps: 10, qrbox: 250 })

    //     htmlscanner.render(onScanSuccess)
    // })
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const overallsalesData = {
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        title: {
            fontSize: 15,
            text: "OverAll Daily Sales"
        },
        axisX: {
          margin: 10,
          labelPlacement: "inside",
          tickPlacement: "inside"
        },
        axisY: {
            // title: "(in USD)",
            prefix: "₱"
        },
        data: [{
            type: "column",
            yValueFormatString: "₱##,###.##",
            dataPoints: [...overalldailysales]
        }]
    }

    const salesData = {
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        title: {
            fontSize: 15,
            text: "Monthly Sales"
        },
        axisX: {
          margin: 10,
          labelPlacement: "inside",
          tickPlacement: "inside"
        },
        axisY: {
            // title: "(in USD)",
            prefix: "₱"
        },
        data: [{
            type: "column",
            yValueFormatString: "₱##,###.##",
            dataPoints: [...monthlysales]
        }]
    }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Dashboard" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        {/* <div>
                            <div>
                                <span style={{fontSize: 25, fontWeight: "bolder"}}>Stores</span>
                            </div>
                            <div style={{ width: "100%", overflowX: "scroll", marginBottom: 0, paddingBottom: 0 }} className='hidescroll'>
                                <div style={{ display: "flex", gap: 5, marginBottom: 0 }}>
                                    {
                                        [...store].map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    <div onClick={()=>window.location.assign(`/store/details/${item.id}`)} style={{ width: 100, height: 150, border: "1px solid #000", borderRadius: 5, position: "relative" }}>
                                                        
                                                        <div style={{position: "absolute", bottom: 0, textAlign: "left", zIndex: 1, padding: 5, fontSize: 12, fontWeight: "bolder"}}>
                                                            {item.store_name}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <br /> */}
                        <div>
                            <CanvasJSChart options={overallsalesData} containerProps={{ width: '100%', height: '300px' }} />
                        </div>
                        <div>
                            <CanvasJSChart options={salesData} containerProps={{ width: '100%', height: '300px' }} />
                        </div>
                        <br />
                        <div>    
                            <div>
                                <span style={{fontSize: 15, fontWeight: "bolder"}}>Transaction</span>
                            </div>                            
                            <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                {
                                    [...sales].map((item, key) => {
                                        return (
                                            <ReportCard key={key} item={item} user={user} store={store} salesitem={salesitem} />
                                        )
                                    }).slice(0, 20)
                                }
                            </div>
                            
                        </div>
                        <br />
                        {/* <br />
                        <br /> */}
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Dashboard;
