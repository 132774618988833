import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import ReportCard from './component/report/ReportCard';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { format, parse } from 'date-fns';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const ProductReport = ({ userInfo }) => {
    const history = useHistory()
    const { id } = useParams();
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [store, setStore] = useState([])
    const [sales, setSales] = useState([])
    const [product, setProduct] = useState([])
    const [salesitem, setSalesItem] = useState([])
    const [salesitems, setSalesItems] = useState([])
    const [user, setUser] = useState([])
    const [search, setSearch] = useState('')

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("store", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setStore(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sales", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSales(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("salesitem", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSalesItems(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sales_report", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setSalesItem(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("product", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setProduct(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("user", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])


    
    const groupedData = salesitem?.reduce((groups, item) => {
        const { product_id, date } = item;
        // console.log(product_id)
        if (product_id == 0) {
            if (!groups[product_id, date]) {
                groups[product_id, date] = [];
            }
            groups[product_id, date].push(item);
        } else {
            if (!groups[product_id, date]) {
                groups[product_id, date] = [];
            }
            groups[product_id, date].push(item);
        }
        return groups;
    }, {});

    // format(date !== null ? date : new Date(), 'yyyy-MM-dd')?.toLowerCase())
    console.log(groupedData)
    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Product Report" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        {/* <div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop:10, paddingBottom: 10}}>
                                <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search Customer Name / Order ID' style={{width: "100%", padding: 5, height: 40, borderRadius: 5, border:"1px solid #ddd"}} />
                            </div>
                        </div> */}
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 25, fontWeight: "bolder"}}>{[...product].filter((i)=>Number(i.id) === Number(id))[0]?.product_name}</span>
                        </div>  
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{ fontSize: 18, fontWeight: "bolder" }}>Revenue: {[...salesitems].filter((i) => Number(i.product_id) === Number(id)).reduce((amount, i) => amount = (i.price * i.qty) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 15, fontWeight: "bolder"}}>Cost: {[...salesitems].filter((i) => Number(i.product_id) === Number(id)).reduce((amount, item) => amount = Number(item.cost * item.qty) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 15, fontWeight: "bolder"}}>Profit: {[...salesitems].filter((i) => Number(i.product_id) === Number(id)).reduce((amount, item) => amount = Number(item.price * item.qty) - Number(item.cost * item.qty) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>    
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                            <span style={{fontSize: 15, fontWeight: "bolder"}}>Product Report</span>
                        </div>  
                        <div>                               
                            <div style={{display: "flex", flexDirection: "column", gap: 10, overflowY: "scroll"}} className='hidescroll'>
                                {
                                    [...salesitem].map((item)=>{
                                        if ([...item.list].filter((i)=>Number(i.product_id) === Number(id)).map((items)=>items).length > 0) {
                                            return (
                                                <div>
                                                    <div style={{fontWeight: "bolder"}}>
                                                        {format(new Date(item.date), 'MMMM dd, yyyy')}
                                                    </div>
                                                    <div>
                                                        {
                                                            [...item.list].filter((i)=>Number(i.product_id) === Number(id)).map((items)=>{
                                                                return (
                                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                                        <div style={{ border: "1px solid #ddd", height: 30, display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 5}}>{format(parse(items.timeonly.split('.')[0], 'HH:mm:ss', new Date), 'hh:mm:ss a')}</div>
                                                                        <div style={{flex: 1, border: "1px solid #ddd", height: 30, display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 5}}>{[...product].filter((i)=>Number(i.id) === Number(items.product_id))[0]?.product_name}</div>
                                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 0}}>
                                                                            <div style={{width: 50, border: "1px solid #ddd", textAlign: "center", fontSize: 10, height: 30}}>
                                                                                <div>{ items.price }</div>
                                                                                <div>{ items.qty }</div>
                                                                            </div>
                                                                            <div style={{width: 70, border: "1px solid #ddd", textAlign: "right", height: 30, fontSize:12, padding: 5}}>{ (Number(items.qty) * Number(items.price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</div>
                                                                        </div>
                                                                        {
                                                                            // JSON.stringify(product[0])
                                                                        }
                                                                        {/* {JSON.stringify([...product].filter((i)=>Number(i.id) === Number(items.product_id))[0]?.product_name)} */}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                        <div></div>
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 0}}>
                                                            <div style={{width: 50, border: "1px solid #ddd", textAlign: "center", fontSize:12, padding: 5}}>{[...item.list].filter((i)=>Number(i.product_id) === Number(id)).reduce((qty, i) => qty = Number(i.qty) + Number(qty), 0)}</div>
                                                            <div style={{width: 70, border: "1px solid #ddd", textAlign: "right", fontSize:12, padding: 5, fontWeight: "bolder"}}>{[...item.list].filter((i)=>Number(i.product_id) === Number(id)).reduce((amount, i) => amount = (i.price * i.qty) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }

                                {/* {
                                    Object.entries(groupedData).map(([id, items]) => {
                                        return (
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start"}}>
                                                <span style={{padding: 5}}>{items[0].date}</span>
                                                <div style={{backgroundColor: "#fff", width: "100%"}}>
                                                    {
                                                        items.map((item)=>{
                                                            return (
                                                                <div style={{border: "1px solid #ddd", borderRadius: 5, padding: 5}}>{[...product].filter((i)=>i.id === item.product_id)[0]?.product_name}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                
                                                <span>{[...product].filter((i)=>i.id === items[0].product_id)[0]?.product_name}</span>
                                                <span>{items?.reduce((qty, item) => qty = Number(item.qty) + Number(qty), 0)}</span>
                                            </div>
                                        )
                                    })
                                } */}
                            </div>
                            
                        </div>
                        
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default ProductReport;
