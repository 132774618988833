import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';

const ProductEdit = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const hideDialog = (event) => {
        // alert("")
        // console.log(event)
        setOpenDialog(prev => ({ ...prev, open: false }))
    }
    return (
        <>

        <Sidebar
            icons={
                <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                    Edit Product
                </div>
            }
            blockScroll={true}
            position='bottom'
            draggable={false}
            style={{ width: '100rem', height: 500, fontSize: 15 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <label>Product Image</label>
                        <div style={{width: 150, height: 150, borderRadius: 5, border: "1px solid #ddd"}}>

                        </div>
                    </div>
                    <div>
                        <label>Product Name</label>
                        <input style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Product Code</label>
                        <input style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Category</label>
                        <input style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Cost</label>
                        <input style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Price</label>
                        <input style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <button onClick={()=>window.location.assign('/payment')} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Update</button>
                    </div>
                </div>
        </Sidebar>
           {/* {
            props.status === 'edit' ?
            <button onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} style={{width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#264682", color: "#fff"}}>Edit</button>
            :

           } */}
           {/* <span onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} className='pi pi-plus-circle' style={{fontSize: 25}}></span>  */}
        </>
    );
}

export default ProductEdit;
