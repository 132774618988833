import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';

const StoreAdd = (props) => {
    const [inputvalue, setInputValue] = useState({...props.item})
    const [openDialog, setOpenDialog] = useState({ open: false })
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const addProductBtn = async () => {
        var formData = new FormData();
        formData.append("add_store", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setStore([...res.data])
            setInputValue({"store_name":"", "address":""})
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const updateProductBtn = async () => {
        var formData = new FormData();
        formData.append("update_store", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/index.php',
            data: formData,
        }).then((res)=>{
            props.setStore([...res.data])
            setInputValue({"store_name":"", "address":""})
            hideDialog()
        })
        .catch((err) => {
            console.log(err);
        });
    }



    return (
        <>

        <Sidebar
            icons={
                <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                    {
                        props.status === 'edit' ?
                        <>
                            Edit Store
                        </>
                        :
                        <>
                            Add Store
                        </>
                    }
                </div>
            }
            blockScroll={true}
            position='bottom'
            draggable={false}
            style={{ width: '100rem', height: 550, fontSize: 15 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <label>Product Image</label>
                        <div style={{width: 150, height: 150, borderRadius: 5, border: "1px solid #ddd"}}>

                        </div>
                    </div>
                    <div>
                        <label>Store Name</label>
                        <input name='store_name' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.product_name} style={{width: "100%", padding: 5, height: 40, borderRadius: 5, border: "1px solid #ddd"}} />
                    </div>
                    <div>
                        <label>Address</label>
                        <textarea name='address' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.product_code} style={{width: "100%", padding: 5, height: 100, borderRadius: 5, border: "1px solid #ddd"}}></textarea>
                    </div>
                    <div>
                        {
                            props.status === 'edit' ?
                            <button onClick={()=>updateProductBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Update</button>
                            :
                            <button onClick={()=>addProductBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Save New</button>
                        }
                    </div>
                </div>
        </Sidebar>
           {
            props.status === 'edit' ?
            <button onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} style={{width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#264682", color: "#fff"}}>Edit</button>
            :
            <span onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} className='pi pi-plus-circle' style={{fontSize: 25}}></span> 
           }
        </>
    );
}

export default StoreAdd;
