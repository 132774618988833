import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import OptionalQty from './component/cashier/OptionalQty';
import CartItemCard from './component/cart/CartItemCard';
// import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Cart = (props) => {
    // if (props.userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(props.userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';


    if(sessionStorage.getItem('cart_list')) {
        var cart_list = sessionStorage.getItem('cart_list');
        // console.log(1)
    } else {
        var cart_list = JSON.stringify([]);
        // console.log(2)
    }
    const [cartlist, setCartList] = useState(JSON.parse(cart_list))
    const cartdata = cartlist?.filter((i) => {
        return i;
    });

    const ClearData = () => {
        setCartList([])
        sessionStorage.setItem('cart_list', JSON.stringify([]));
    }

    const SubTotal = cartdata?.reduce((amount, item) => amount = (item.price * item.qty) + amount, 0);
    const TotalQty = cartdata?.reduce((qty, item) => qty = Number(item.qty) + Number(qty), 0);


    if (cartdata.length === 0) {
        window.location.assign('/cashier')
        // return (<>Invalid</>)
    }
    // console.log(cartdata)

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header titleheader="Cart" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    
                    <div className="container-fluid">
                        <div>    
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5}}>
                                <span style={{fontSize: 15, fontWeight: "bolder"}}>Items</span>
                                <span onClick={()=>ClearData()} style={{fontSize: 15, fontWeight: "bolder", color: "red", textDecoration: "underline"}}>Clear</span>
                            </div>                            
                            <div style={{display: "flex", flexDirection: "column", gap: 5, overflowY: "scroll", height: window.innerHeight - 300, flex: 1}} className='hidescroll'>
                                {
                                    [...cartdata].map((item, key) => {
                                        return (
                                            <CartItemCard key={key} item={item} cartlist={cartlist} cartdata={cartdata} setCartList={setCartList} />
                                        )
                                    })
                                }
                            </div>
                            <div style={{width: "100%", flex: 2}}>
                                <div style={{display: "flex", flexDirection: "column", gap: 2}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span style={{fontWeight: "bolder", fontSize: 20}}>Summary</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Total Item/s</span>
                                        <span>{TotalQty}</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Sub-Total</span>
                                        <span>{SubTotal}</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Tax</span>
                                        <span>0.00</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span>Discount</span>
                                        <span>0.00</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <span style={{fontWeight: "bolder"}}>Grand Total</span>
                                        <span style={{fontWeight: "bolder"}}>{SubTotal}</span>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                    <button onClick={()=>window.location.assign('/cashier')} style={{width: 50, height: 40, borderRadius: 5, border: "1px solid #374151", backgroundColor:"#ddd", color: "#fff"}}>
                                        <span className='pi pi-angle-left' style={{color: "#000"}}></span>
                                    </button>
                                    <button onClick={()=>window.location.assign('/payment')} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#374151", color: "#fff"}}>Payment</button>
                                </div>
                            </div>
                            
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Cart;
